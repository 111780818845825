import axios, { AxiosResponse } from 'axios';
import Instance from './instance';
import IScenariosRespponse from 'src/models/response/IScenariosResponse';
import IScenario from 'src/models/IScenario';
import IKnowledge from 'src/models/IKnowledge';
import IFileRequest from 'src/models/request/IFileRequest';
import ISignS3Response from 'src/models/response/ISignS3Response';
import IFile from 'src/models/IFile';

const axiosConfigS3 = axios.create({
    baseURL: '',
    validateStatus: (status) => status < 500,
    withCredentials: false,
});

const instance: any = new Instance().getAxiosIns();

class KnowledgeAPI {
    public static getKnowledges(brandId: string): Promise<AxiosResponse<IKnowledge[]>> {
        return instance.get(`/brands/${brandId}/knowledge_directory`);
    }

    public static getKnowledge(brandId: string, knowledgeId: string): Promise<AxiosResponse<IKnowledge>> {
        return instance.get(`/brands/${brandId}/knowledge_directory/${knowledgeId}`);
    }

    public static createKnowledge(brandId: string, name: string): Promise<AxiosResponse<any>> {
        return instance.post(`/brands/${brandId}/knowledge_directory`, {
            name,
        });
    }

    public static signS3(brandId: string, knowledgeId: string): Promise<AxiosResponse<ISignS3Response>> {
        return instance.post(`/brands/${brandId}/knowledge_directory/${knowledgeId}/knowledge_file/sign-s3`);
    }

    public static getFiles(brandId: string, knowledgeId: string): Promise<AxiosResponse<IFile[]>> {
        return instance.get(`/brands/${brandId}/knowledge_directory/${knowledgeId}/knowledge_file`);
    }

    public static createFile(brandId: string, knowledgeId: string, body: IFileRequest): Promise<AxiosResponse<any>> {
        return instance.post(`/brands/${brandId}/knowledge_directory/${knowledgeId}/knowledge_file`, {
            body,
        });
    }
    
    public static addFileToS3(url: string, file: any, type: string): Promise<AxiosResponse<any | any>> {
        return axiosConfigS3.put(url, file, {
            headers: {
                'Content-Type': type,
            },
        });
    }

}

export default KnowledgeAPI;
