import axios, { AxiosResponse } from 'axios';
import Instance from './instance';
import IScenariosRespponse from 'src/models/response/IScenariosResponse';
import IScenario from 'src/models/IScenario';

const axiosConfigGraf = axios.create({
    baseURL: 'http://217.25.91.83:8000',
    validateStatus: (status) => status < 500,
    withCredentials: false,
});

const instance: any = new Instance().getAxiosIns();

class ScenarioAPI {
    public static getScenarios(brandId: string, limit: number, offset: number): Promise<AxiosResponse<IScenariosRespponse>> {
        return instance.get(`/brands/${brandId}/scenario`, {
            params: {
                limit,
                offset,
            }
        });
    }

    public static getScenario(brandId: string, scenarioId: string): Promise<AxiosResponse<IScenario>> {
        return instance.get(`/brands/${brandId}/scenario/${scenarioId}`);
    }

    public static createScenario(brandId: string, name: string, json_object: any): Promise<AxiosResponse<any>> {
        return instance.post(`/brands/${brandId}/scenario`, {
            name,
            json_object,
        });
    }

    public static editScenario(brandId: string, scenarioId: string, name: string, json_object: any): Promise<AxiosResponse<any>> {
        return instance.patch(`/brands/${brandId}/scenario/${scenarioId}`, {
            name,
            json_object,
        });
    }

    public static createGraph(config_id: string): Promise<AxiosResponse<any>> {
        
        return instance.post(`/agent/create_graph`, {
            config_id,
            debug_mode: false,
        });
    }
}

export default ScenarioAPI;
