import { CustomInput } from 'src/components/ui/customInput'
import styles from './style.module.scss'
import { MyButton } from 'src/components/ui/myButton'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import BrandsAPI from 'src/api/BrandsAPI';
import IBrandResponse from 'src/models/response/IBrandResponse';
import mainActions from 'src/store/actions/mainActions';

interface IProps {
    handleClose: () => void
}

const Bitrix = ({handleClose}: IProps) => {
    const [bitrix_subdomain, setBitrix_subdomain] = useState<string>('');
    const [bitrix_webhook_url, setBitrix_webhook_url] = useState<string>('');
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    
    const { brandId } = useParams();

    const dispatch = useAppDispatch();
        
    const brandState = (useAppSelector((state) => state.mainState.brand))[brandId || ''];

    const compliteState = (data: IBrandResponse) => {
        setBitrix_subdomain(data.brand.bitrix_subdomain || '');
        setBitrix_webhook_url(data.brand.bitrix_webhook_url || '');
    }

    const handleSaveBitrix = () => {
        if (brandId) {
            setIsDisabled(true);
            BrandsAPI.createBrandBitrix(brandId, {
                bitrix_subdomain,
                bitrix_webhook_url,
            })
            .then(response => {
                if (response.status <= 204) {
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    handleClose();
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
                setIsDisabled(false);
            })
            .catch((err) => {
                setIsDisabled(false);
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
        }
    }

    const getBrand = (brandId: string) => {
        BrandsAPI.getBrand(brandId)
            .then(response => {
                if (response.status <= 204) {
                    compliteState(response.data)
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
            })
            .catch((err) => {
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
    }

    useEffect(() => {
        if (brandId) {
            getBrand(brandId);
        }
    }, [brandState])

    return (
        <div className={styles.channelsBlock}>
            <div className={styles.creationBlock}>
                <div className={styles.inputContainer}>
                    <div className={styles.title}>Подключение Bitrix</div>
                    <CustomInput
                        value={bitrix_subdomain}
                        onChange={(e) => setBitrix_subdomain(e.target.value)}
                        placeholder='Домен пользователя'
                    />
                    <CustomInput
                        value={bitrix_webhook_url}
                        onChange={(e) => setBitrix_webhook_url(e.target.value)}
                        placeholder='Вебхук'
                    />
                    <MyButton 
                        disabled={!bitrix_subdomain || !bitrix_webhook_url || isDisabled} 
                        handleClickProps={handleSaveBitrix}
                    >
                        Сохранить изменения
                    </MyButton>
                </div>
            </div>
        </div>
    )
}

export default Bitrix;
