import { TextareaHTMLAttributes } from 'react';
import style from './style.module.scss'
import classNames from 'classnames';

interface Props extends TextareaHTMLAttributes<HTMLTextAreaElement> {
}

const CustomTextarea = (props: Props) => {
    const {
        placeholder,
        value = '',
        name,
        onChange,
        disabled,
    } = props;

    return (
        <div className={classNames(style.container, {[style.withoutlable]: !placeholder})}>
            <textarea 
                {...props}
                onChange={onChange}
            />
            {placeholder &&
                <div className={style.lable}>
                    {placeholder}
                </div>
            }
        </div>
    )
}

export default CustomTextarea
