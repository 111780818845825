import { createSlice } from '@reduxjs/toolkit';
import mainActions from '../actions/mainActions';
import IBrandsResponse from 'src/models/response/IBrandsResponse';
import IBrandResponse from 'src/models/response/IBrandResponse';
import IBrand from 'src/models/IBrand';
import IChannel from 'src/models/IChannel';

export interface ILink {
    type: 'link',
    name: string,
    link: string,
}

export interface IChannelFolder {
    type: 'folder',
    name: string,
    id: string,
    folders: {
        dialogues: ILink,
        editor: ILink,
        requests: ILink,
    }
}

export interface IChannelsFolder {
    type: 'folder',
    name: string,
    id: string,
    folders: {
        [key: string]: IChannelFolder,
    }
}

export interface IIntegrationFolder {
    type: 'folder',
    name: string,
    id: string,
    folders: {
        amo: ILink,
        bitrix: ILink,
    }
}

export interface IBrandFolder {
    type: 'folder',
    name: string,
    id: string,
    folders: {
        channels: IChannelsFolder,
        // scenarios: ILink,
        integrations: IIntegrationFolder,
        editor: ILink,
    }
}

interface ITabs {
    link: string,
    name: string,
    id: string,
    fullName: string,
}

export interface mainState {
    folders: {
        [key: string]: IBrandFolder
    };
    isLoading: boolean,
    tabs: ITabs[],
    activeTab: string,
    brands: IBrandsResponse,
    brand: {
        [key: string]: IBrandResponse;
    },
    currentBrand: IBrand | null,
    currentChannel: IChannel | null,
}

const initialState: mainState = {
    folders: {},
    isLoading: false,
    tabs: [],
    activeTab: '',
    brands: {
        amount: 0,
        brands: [],
    },
    brand: {},
    currentBrand: null,
    currentChannel: null,
};

const name = 'menu';
const mainSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        //Set brands
        builder.addCase(mainActions.getBrands.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(mainActions.getBrands.fulfilled, (state, action) => {
            action.payload.data.brands.forEach((brand) => {
                state.folders[brand.id] = {
                    type: 'folder',
                    name: brand.name,
                    id: brand.id,
                    folders: {
                        channels: {
                            type: 'folder',
                            id: 'ch_' + brand.id,
                            name: 'Каналы',
                            folders: {}
                        },
                        integrations: {
                            type: 'folder',
                            name: 'Интеграции',
                            id: 'int_' + brand.id,
                            folders: {
                                amo: {
                                    type: 'link',
                                    name: 'Amo',
                                    link: `/brand/${brand.id}/amo`,
                                },
                                bitrix: {
                                    type: 'link',
                                    name: 'Bitrix',
                                    link: `/brand/${brand.id}/bitrix`,
                                },
                            }
                        },
                        editor: {
                            type: 'link',
                            name: 'Информация',
                            link: `/brand/${brand.id}`
                        },
                        // scenarios: {
                        //     type: 'link',
                        //     name: 'сценарии',
                        //     link: `/brand/${brand.id}/scenarios`,
                        // },
                    }
                }
            })
            state.brands = action.payload.data;
            state.isLoading = false;
        });
        builder.addCase(mainActions.getBrands.rejected, (state) => {
            state.isLoading = false;
        });
        //Set Channels
        builder.addCase(mainActions.getChannels.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(mainActions.getChannels.fulfilled, (state, action) => {
            const channelsFolder: {[key: string]: IChannelFolder} = {};
            action.payload.data.channels.forEach((channel) => {
                channelsFolder[channel.id] = {
                    type: 'folder',
                    id: channel.id,
                    name: channel.name,
                    folders: {
                        dialogues: {
                            type: 'link',
                            name: 'Диалоги',
                            link: `/brand/${action.payload.brandId}/channel/${channel.id}/dialogues`,
                        },
                        requests: {
                            type: 'link',
                            name: 'Заявки',
                            link: `/brand/${action.payload.brandId}/channel/${channel.id}/subscriptions`,
                        },
                        editor: {
                            type: 'link',
                            name: 'Информация',
                            link: `/brand/${action.payload.brandId}/channel/${channel.id}`,
                        },
                    }
                }
            })
            state.folders = {
                ...state.folders,
                [action.payload.brandId]: {
                    ...state.folders[action.payload.brandId],
                    folders: {
                        ...state.folders[action.payload.brandId].folders,
                        channels: {
                            ...state.folders[action.payload.brandId].folders.channels,
                            folders: channelsFolder,
                        }
                    }
                }
            }
            state.isLoading = false;
        });
        builder.addCase(mainActions.getChannels.rejected, (state) => {
            state.isLoading = false;
        });
        //Get brand
        builder.addCase(mainActions.getBrand.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(mainActions.getBrand.fulfilled, (state, action) => {
            state.brand = {
                ...state.brand,
                [action.payload.data.brand.id]: action.payload.data,
            };
            state.isLoading = false;
        });
        builder.addCase(mainActions.getBrand.rejected, (state) => {
            state.isLoading = false;
        });
        //add tab
        builder.addCase(mainActions.addTab, (state, action) => {
            const index = state.tabs.findIndex(item => item.id === action.payload.id);
            if (index === -1) {
                state.tabs = [...state.tabs, action.payload];
            }
            state.activeTab = action.payload.id;
        });
        //close tab
        builder.addCase(mainActions.closeTab, (state, action) => {
            state.tabs = [...state.tabs.filter(item => item.id !== action.payload.id)];
        });
        //set active tab
        builder.addCase(mainActions.setActiveTab, (state, action) => {
            state.activeTab = action.payload.id;
        });
        //clear state
        builder.addCase(mainActions.clearState, (state, action) => {
            state.activeTab = '';
            state.brand = {};
            state.brands = {
                amount: 0,
                brands: [],
            };
            state.folders = {};
            state.isLoading = false;
            state.tabs = [];
        });
        //set current brand
        builder.addCase(mainActions.setCurrentBrand, (state, action) => {
            state.currentBrand = action.payload;
        });
        //set current channel
        builder.addCase(mainActions.setCurrentChannel, (state, action) => {
            state.currentChannel = action.payload;
        });
    },
});

export default mainSlice.reducer;
