import styles from './style.module.scss';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import { ArrowSelect } from 'src/assets/icons/icons';

interface Props {
    options: any[];
    name?: string;
    onChangeMethod?: (value: any) => void;
    selectedId?: string;
    placeholder?: string;
    error?: boolean;
    title?: string;
    style?: any;
    disabled?: boolean;
}

export const CustomSelect = (props: Props) => {
    const {
        options,
        onChangeMethod,
        selectedId = '',
        placeholder,
        error = false,
        name,
        title = 'name',
        style,
        disabled = false,
    } = props;

    const selectRef = useRef<HTMLInputElement>(null);

    const [value, setValue] = useState<string>('');

    const [isViewSelect, setIsViewSelect] = useState(false);

    const handleSelect = (data: any) => {
        if (onChangeMethod) {
            onChangeMethod(data.id);
        }
        setIsViewSelect(false);
    };

    const visibleValue = (id: string) => {
        const item = options.find((item) => item.id === id);
        return item ? item[title] : '';
    };

    const handleChange = () => {
        setValue(value);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsViewSelect(false);
        }, 250);
    };

    const handleFocus = () => {
        setIsViewSelect(true);
    };

    const handleArrowClick = () => {
        if (isViewSelect) {
            setIsViewSelect(false);
        } else {
            selectRef.current?.focus();
        }
    };

    return (
        <div className={classNames(styles.wrapperCustomSelect, {[styles.withoutlable]: !placeholder})} style={style}>
            <input
                ref={selectRef}
                className={classNames(styles.customSelect, { [styles.error]: error }, { [styles.disabled]: disabled })}
                value={visibleValue(selectedId)}
                placeholder={placeholder}
                name={name}
                disabled={disabled}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
            {placeholder &&
                <div className={styles.lable}>
                    {placeholder}:
                </div>
            }
            {isViewSelect && (
                <div className={styles.blockOptions}>
                    {options.map((data, index) => (
                        <div key={index + ')'} className={styles.option} onMouseDown={() => handleSelect(data)}>
                            {data[title]}
                        </div>
                    ))}
                </div>
            )}
            <div
                className={classNames({
                    [styles.arrowSelect]: true,
                    [styles.open]: isViewSelect,
                    [styles.close]: !isViewSelect,
                    [styles.disabled_icon]: disabled,
                })}
                onClick={handleArrowClick}
            >
                <ArrowSelect />
            </div>
        </div>
    );
};
