import styles from './style.module.scss';
import { InputHTMLAttributes, useState } from 'react';
import Eye from '../../../assets/icons/eye-icon.svg'
import ClosedEye from '../../../assets/icons/closed-eye-icon.svg'
import classNames from 'classnames';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    error?: boolean;
    errorMessage?: string;
    style?: any;
    icon?: any;
    withoutlable?: boolean;
}

export const CustomInput = (props: Props) => {
    const {
        placeholder,
        value = '',
        name,
        onChange,
        disabled,
        error,
        errorMessage,
        type = 'text',
        style,
        icon,
        withoutlable,
    } = props;
    
    const [isPasswordType, setIsPasswordType] = useState<boolean>(true);

    return (
        <div className={classNames(styles.wrapperInput, {[styles.withoutlable]: withoutlable || !placeholder})} style={style}>
            <input
                {...props}
                disabled={disabled}
                className={`${styles.customInput} ${error && styles.error} ${icon && styles.iconStyle}`}
                placeholder={placeholder}
                value={value}
                name={name}
                type={(type === 'password' && !isPasswordType) ? 'text' : type}
                onChange={onChange}
            />
            {placeholder && !withoutlable &&
                <div className={styles.lable}>
                    {placeholder}:
                </div>
            }
            {icon && 
                <div className={styles.iconContainer}>
                    {icon}
                </div>
            }
            {type === 'password' ? (
                <img
                    className={styles['input_eye-img']}
                    src={isPasswordType ? Eye : ClosedEye}
                    alt='password eye'
                    onClick={() => setIsPasswordType(!isPasswordType)}
                />
            ) : null}
            {error && errorMessage &&
                <span className={styles.errorMessage}>{errorMessage}</span>
            }
        </div>
    );
};
