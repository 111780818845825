import { CustomInput } from 'src/components/ui/customInput'
import styles from './style.module.scss'
import { MyButton } from 'src/components/ui/myButton'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import BrandsAPI from 'src/api/BrandsAPI';
import mainActions from 'src/store/actions/mainActions';
import IBrandResponse from 'src/models/response/IBrandResponse';

interface IProps {
    handleClose: () => void
}

const Amo = ({handleClose}: IProps) => {
    const [amo_client_id, setAmo_client_id] = useState<string>('');
    const [amo_client_secret, setAmo_client_secret] = useState<string>('');
    const [amo_subdomain, setAmo_subdomain] = useState<string>('');
    const [amo_user_id, setAmo_user_id] = useState<string>('');
    const [amo_code, setAmo_code] = useState<string>('');
    const [isCreate, setIsCreate] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    
    const { brandId } = useParams();

    const dispatch = useAppDispatch();
    
    // const brandState = (useAppSelector((state) => state.mainState.brand))[brandId || ''];

    const compliteState = (data: IBrandResponse) => {
        setAmo_client_id(data.brand.amo_client_id || '');
        setAmo_client_secret(data.brand.amo_client_secret || '');
        setAmo_subdomain(data.brand.amo_subdomain || '');
        setAmo_user_id(data.brand.amo_user_id || '');
        setIsCreate(data.brand.amo_user_id ? false : true);
    }

    const handleSaveChannel = () => {
        if (brandId) {
            setIsDisabled(true);
            BrandsAPI.createBrandAmo(brandId, {
                amo_client_id,
                amo_client_secret,
                amo_code,
                amo_subdomain,
                amo_user_id,
            })
            .then(response => {
                if (response.status <= 204) {
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    handleClose();
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
                setIsDisabled(false);
            })
            .catch((err) => {
                setIsDisabled(false);
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
        }
    }

    const getBrand = (brandId: string) => {
        BrandsAPI.getBrand(brandId)
            .then(response => {
                if (response.status <= 204) {
                    compliteState(response.data)
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
            })
            .catch((err) => {
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
    }

    const copyLink = () => {
        const input: any = document.createElement('input');
	    input.id = 'temp';
	    input.style.height = 0;
	    document.body.appendChild(input);
	    input.value = `https://ai.chat.test-loyalty.ru/api/webhook/amo/${brandId}`;
	    const selector: any = document.querySelector('#temp');
	    selector.select();
	    document.execCommand('copy');
	    document.body.removeChild(input);

    }

    useEffect(() => {
        if (brandId) {
            getBrand(brandId);
        }
    }, [brandId])

    return (
        <div className={styles.channelsBlock}>
            <div className={styles.creationBlock}>
                <div className={styles.inputContainer}>
                    <div className={styles.title}>Подключение AMO</div>
                    <CustomInput
                        value={amo_client_id}
                        onChange={(e) => setAmo_client_id(e.target.value)}
                        placeholder='ID интеграции'
                    />
                    <CustomInput
                        value={amo_client_secret}
                        onChange={(e) => setAmo_client_secret(e.target.value)}
                        placeholder='Ключ интеграции'
                    />
                    <CustomInput
                        value={amo_subdomain}
                        onChange={(e) => setAmo_subdomain(e.target.value)}
                        placeholder='Домен пользователя'
                    />
                    <CustomInput
                        value={amo_user_id}
                        onChange={(e) => setAmo_user_id(e.target.value)}
                        placeholder='ID пользователя'
                    />
                    {isCreate &&
                        <CustomInput
                            value={amo_code}
                            onChange={(e) => setAmo_code(e.target.value)}
                            placeholder='Одноразовый код'
                        />
                    }
                    {isCreate &&
                        <div className={styles.btn}>
                            <MyButton 
                                disabled={!amo_client_id || !amo_client_secret || !amo_subdomain || !amo_code || isDisabled} 
                                handleClickProps={handleSaveChannel}
                            >
                                Сохранить изменения
                            </MyButton>
                            <MyButton 
                                handleClickProps={copyLink}
                                className={styles.copyButton}
                            >
                                Копировать ссылку
                            </MyButton>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Amo;
