import { ReactNode } from 'react';
import { MyButton } from '../ui/myButton'
import style from './style.module.scss'
import { NewCross } from 'src/assets/icons/icons';

interface IProps {
    handleOk?: () => void,
    handleClose: () => void,
    children?: ReactNode;
    cross?: boolean;
}

const ModalContent = ({handleOk, handleClose, children, cross}: IProps) => {

    return (
        <div className={style.overlay}>
            <div className={style.container}>
                {cross &&
                    <div className={style.crossIcon} onClick={handleClose}>
                        <NewCross/>
                    </div>
                }
                <div>
                    {children}
                </div>
                {(handleOk || !cross) &&
                    <div className={style.buttons}>
                        {!cross &&
                            <MyButton handleClickProps={handleClose}>
                                Отмена
                            </MyButton>
                        }
                        {handleOk &&
                            <MyButton handleClickProps={handleOk}>
                                Ок
                            </MyButton>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default ModalContent
