import { Navigate, Route, Routes } from 'react-router-dom';
import Layout from '../components/layout';
import Dialogues from 'src/pages/dialogues';
import Brands from 'src/pages/brands';
import BrandPage from 'src/pages/brands/brandPage';
import Auth from 'src/pages/auth';
import Registaration from 'src/pages/registration';
import DialoguePage from 'src/pages/dialogues/dialoguePage';
import Subscriptions from 'src/pages/subscriptions';
import Channels from 'src/pages/channels';
import Amo from 'src/pages/brands/amo';
import Bitrix from 'src/pages/brands/bitrix';
import ChannelPage from 'src/pages/channels/channelPage';
import Scenarios from 'src/pages/scenarios';
import ScenarioPage from 'src/pages/scenarios/scenarioPage';
import Knowledges from 'src/pages/knowledges';
import KnowledgePage from 'src/pages/knowledges/knowledgePage';

const Router = () => {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path='/' element={<Navigate to='/brands' />} />

                <Route path='/brands' element={<Brands />} />
                <Route path='/brand/:brandId?' element={<BrandPage />} />
                {/* <Route path='/brand/:brandId/amo' element={<Amo />} />
                <Route path='/brand/:brandId/bitrix' element={<Bitrix />} /> */}
                <Route path='/brand/:brandId/channels' element={<Channels />} />
                <Route path='/brand/:brandId/knowledges' element={<Knowledges />} />
                <Route path='/brand/:brandId/knowledge/:knowledgeId' element={<KnowledgePage />} />
                <Route path='/brand/:brandId/scenarios' element={<Scenarios />} />
                <Route path='/brand/:brandId/scenario/:scenarioId?' element={<ScenarioPage />} />
                <Route path='/brand/:brandId/channel/:channelId?' element={<ChannelPage />} />
                <Route path='/brand/:brandId/channel/:channelId/dialogues' element={<Dialogues />} />
                <Route path='/brand/:brandId/channel/:channelId/dialogue/:dialogueId' element={<DialoguePage />} />
                <Route path='/brand/:brandId/channel/:channelId/subscriptions' element={<Subscriptions />} />
            </Route>

            <Route path='/auth' element={<Auth />} />
            <Route path='/registration' element={<Registaration />} />
        </Routes>
    );
};

export default Router;
