import { CustomInput } from 'src/components/ui/customInput';
import styles from './style.module.scss';
import { MyButton } from 'src/components/ui/myButton';
import { useEffect, useState } from 'react';
import IDialog from 'src/models/IDialog';
import PaginationComponent from 'src/components/ui/PaginationComponent';
import DialoguesAPI from 'src/api/DialoguesAPI';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import ChannelsAPI from 'src/api/ChannelsAPI';
import { CustomSelect } from 'src/components/ui/customSelect';
import FilePicker from 'src/components/ui/filePicker';
import { CustomSearch } from 'src/components/ui/customSearch';
import TableMain from 'src/components/table';
import { CrossIcon, DeleteIcon } from 'src/assets/icons/icons';
import DialoguePage from './dialoguePage';
import Modal from 'src/components/modal';
import classNames from 'classnames';
import CreateDialogue from './createDialogue';
import { CustomToggle } from 'src/components/ui/customToggle';

const Dialogues = () => {
    const [dialogues, setDialogues] = useState<IDialog[]>([]);
    const [tokens, setTokens] = useState<string>('');    

    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);

    const [searchValue, setSearchValue] = useState<string>('');

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const { brandId, channelId } = useParams();


    const getChannel = () => {
        if (brandId && channelId) {
            ChannelsAPI.getChannel(brandId, channelId)
                .then(response => {
                    if (response.status <= 204) {
                        setTokens(`${response.data.in_tokens} / ${response.data.out_tokens}`)
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const getDialogues = (limit: number, offset: number, search?: string) => {
        if (brandId && channelId && brandId !== 'all' && channelId !== 'all') {
            DialoguesAPI.getDialogues(brandId, channelId, limit, offset, search ?? searchValue)
                .then(response => {
                    if (response.status <= 204) {
                        setDialogues(response.data.dialogues);
                        setCount(response.data.amount);
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        } else {
            setDialogues([]);
        }
    }

    const handleEditActive = (dialogueId: string, isActive: boolean) => {
        if (brandId && channelId) {
            DialoguesAPI.editDialog(brandId, channelId, dialogueId, !isActive)
                .then(response => {
                    if (response.status <= 204) {
                        getDialogues(20, 0);
                        setPage(1);
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const handleClick = (id?: string) => {
        if (id) {
            navigate(`/brand/${brandId}/channel/${channelId}/dialogue/${id}`);
        } else {
            navigate(`/brand/${brandId}/channel/${channelId}/dialogue`);
        }
    }

    const handleClickSub= () => {
        navigate(`/brand/${brandId}/channel/${channelId}/subscriptions`)
    }

    const handleSearch = (value: string) => {
        setSearchValue(value);
        getDialogues(20, 0, value)
    }

    useEffect(() => {
        getDialogues(20, 0);
        getChannel();
    }, [brandId, channelId]);

    return (
        <div className={styles.body}>
            <div className={styles.buttonContainer}>
                <MyButton 
                    handleClickProps={() => handleClickSub()} 
                    disabled={brandId === 'all' || channelId === 'all'}
                >Перейти к заявкам на уведомление</MyButton>
            </div>

            <div className={styles.rightBlock}>
                <div className={classNames(styles.searchBlock, {[styles.marginLeft]: brandId !== 'all'})}>
                    <CustomSearch 
                        onChangeMethod={handleSearch}
                        placeholder='Поиск'
                    />
                </div>
                
                <CreateDialogue/>

                <div className={styles.tokens}>
                    Токены: {tokens}
                </div>
                
                <div>
                    <TableMain>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Имя клиента</th>
                                <th>Никнейм клиента</th>
                                <th>Номер телефона</th>
                                <th>Дата и время последнего сообщения</th>
                                <th>Статус чата</th>
                                <th>Ассистент</th>
                                <th>Токены</th>
                                <th>Действия</th>
                            </tr>
                        </thead>
                        <tbody className={styles['']}>
                            {dialogues.map((item, index) => (
                                <tr key={item.id} onClick={() => handleClick(item.id)} className={styles.cursor}>
                                    <td>
                                        <div className={styles.tdTextName}>{(page-1)*20+index+1}</div>
                                    </td>
                                    <td>
                                        <div className={styles.tdText}>{item.contact_name}</div>
                                    </td>
                                    <td>
                                        <div className={styles.tdText}>{item.contact_username}</div>
                                    </td>
                                    <td>
                                        <div className={styles.tdText}>{item.contact_phone || '-'}</div>
                                    </td>
                                    <td>
                                        <div className={styles.tdText}>{moment(item.last_contact).format('DD-MM-YY hh:mm:ss')}</div>
                                    </td>
                                    <td>
                                        <div className={classNames(styles.tdText, styles.status, {[styles.active]: item.is_active})}>
                                            {item.is_active ? 'Активен' : 'Выключен'}
                                        </div>
                                    </td>
                                    <td>
                                        <div className={classNames(styles.tdText, styles.status, {[styles.active]: item.is_active})}>
                                            {item.is_active ? 'Включен' : 'Выключен'}
                                        </div>
                                    </td>
                                    <td>
                                        <div className={styles.tdText}>{`${item.in_tokens} / ${item.out_tokens}`}</div>
                                    </td>
                                    <td>
                                        <div 
                                            className={`${styles.tdText} ${styles.action}`} 
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleEditActive(item.id, item.is_active);
                                            }}
                                        >
                                            <CustomToggle
                                                handleClick={() => {}}
                                                check={item.is_active}
                                                id='is_active'
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </TableMain>

                    {count > 20 &&
                        <PaginationComponent
                            count={count}
                            limit={20}
                            page={page}
                            setPage={setPage}
                            onChange={getDialogues}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default Dialogues;
