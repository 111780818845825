import { ReactNode, TableHTMLAttributes } from 'react';
import style from './style.module.scss';

interface Props extends TableHTMLAttributes<HTMLTableElement> {
    children?: ReactNode;
}

const TableMain = ({children}: Props) => {
    return (
        <div className={style.container}>
            <table>
                {children}
            </table>
        </div>
    )
}

export default TableMain
