import style from './style.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import { AxiosResponse } from 'axios';
import useDebounce from 'src/utils/useDebounce';
import { ArrowSelect, SearchIcon } from 'src/assets/icons/icons';
import { CustomInput } from '../customInput';
import classNames from 'classnames';

interface Props {
    getMethod: (...value: any) => Promise<AxiosResponse<any>>;
    onChangeMethod: (value: any) => void;
    selectedId: string;
    getParams?: any;
    placeholder?: string;
    title?: string;
    name: string;
    error?: boolean;
    disabled?: boolean;
}

export const CustomSelectSearch = (props: Props) => {
    const {
        getMethod,
        onChangeMethod,
        selectedId,
        placeholder,
        name,
        title = 'name',
        error,
        disabled,
        getParams = [],
    } = props;

    const [value, setValue] = useState<string>('');
    const [options, setOptions] = useState<any[]>([]);
    const [isFirst, setIsFirst] = useState<boolean>(true);

    const [isViewSelect, setIsViewSelect] = useState(false);

    const debonceValue = useDebounce(value, 800);
    
    const selectRef = useRef<HTMLInputElement>(null);

    const visibleValue = (id: string) => {
        const item = options.find((item) => item.id === id);
        return item ? item[title] : '';
    };

    const handleChange = (event: any) => {
        setValue(event.target.value);
    };

    const handleChangeMethod = (value: string) => {
        getMethod(...getParams, 20, 0, isViewSelect ? value : '')
            .then(response => {
                if (response.status <= 204) {
                    setOptions(response.data[name]);
                    
                    if (isFirst && selectedId) {
                        setIsFirst(false);
                        setValue(visibleValue(selectedId));
                        if (onChangeMethod) {
                            onChangeMethod(response.data[name].find((i: any) => i.id === selectedId));
                        }
                    }
                }
            })
    } 

    const handleSelect = (data: any) => {
        if (onChangeMethod) {
            onChangeMethod(data);
        }
        setIsViewSelect(false);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsViewSelect(false);
        }, 250);
    };

    const handleFocus = () => {
        handleChangeMethod('');
        setIsViewSelect(true);
    };

    const handleArrowClick = () => {
        if (isViewSelect) {
            setIsViewSelect(false);
        } else {
            selectRef.current?.focus();
        }
    };

    useEffect(() => {
        setValue(visibleValue(selectedId));
    }, [selectedId]);

    useEffect(() => {
        handleChangeMethod(debonceValue)
    }, [debonceValue]);

    return (
        // <div className={style.wrapperCustomSelect}>
        //     <CustomInput
        //         name={name}
        //         value={value}
        //         placeholder={placeholder}
        //         onInput={handleChange}
        //     />
        //     <div className={style.icon}>
        //         <SearchIcon />
        //     </div>
        // </div>
        <div className={style.wrapperCustomSelect} style={style}>
            <input
                ref={selectRef}
                className={classNames(style.customSelect, { [style.error]: error }, { [style.disabled]: disabled })}
                value={value}
                placeholder={placeholder}
                name={name}
                disabled={disabled}
                onChange={handleChange}
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
            {(!!visibleValue(selectedId)) &&
                <div className={style.lable}>
                    {placeholder}
                </div>
            }
            {isViewSelect && (
                <div className={style.blockOptions}>
                    {options.map((data, index) => (
                        <div key={index + ')'} className={style.option} onMouseDown={() => handleSelect(data)}>
                            {data[title]}
                        </div>
                    ))}
                </div>
            )}
            <div
                className={classNames({
                    [style.arrowSelect]: true,
                    [style.open]: isViewSelect,
                    [style.close]: !isViewSelect,
                    [style.disabled_icon]: disabled,
                })}
                onClick={handleArrowClick}
            >
                <ArrowSelect />
            </div>
        </div>
    );
};
