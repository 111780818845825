import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import BrandsAPI from 'src/api/BrandsAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomInput } from 'src/components/ui/customInput';
import { MyButton } from 'src/components/ui/myButton';
import IBrand from 'src/models/IBrand';
import IEditBrandRequest from 'src/models/request/IEditBrandRequest';
import PushesAPI from 'src/api/PushesAPI';
import IPush from 'src/models/IPush';
import { CustomSelect } from 'src/components/ui/customSelect';
import { hours, minutes } from 'src/utils/constants/timeConstant';
import IBrandResponse from 'src/models/response/IBrandResponse';
import mainActions from 'src/store/actions/mainActions';
import { CustomCheckbox } from 'src/components/ui/customCheckbox';
import Bitrix from '../bitrix';
import Amo from '../amo';
import Modal from 'src/components/modal';
import ModalContent from 'src/components/modalContent';
import Steps from 'src/pages/scenarios/scenarioPage/Steps';
import Channels from 'src/pages/channels';
import Scenarios from 'src/pages/scenarios';
import { CustomToggle } from 'src/components/ui/customToggle';
import ChannelsAPI from 'src/api/ChannelsAPI';

const stepItems = ['Бренд', 'Каналы', 'Список сценариев'];

const BrandPage = () => {
    const [currentBrand, setCurrentBrand] = useState<IBrand | null>(null);
    const [name, setName] = useState<string>('');
    const [assistant_id, setAssistant_id] = useState<string>('');
    const [wazzup_api_key, setWazzup_api_key] = useState<string>('');
    const [openai_api_key, setOpenai_api_key] = useState<string>('');
    const [yclients_api_key, setYclients_api_key] = useState<string>('');
    const [yclients_company_id, setYclients_company_id] = useState<string>('');
    const [tokens, setTokens] = useState<string>('');
    const [interval, setInterval] = useState<string>('');
    const [intervals, setIntervals] = useState<IPush[]>([]);
    const [isAddInterval, setIsAddInterval] = useState<boolean>(false);
    const [turn_off_on_save, setTurn_off_on_save] = useState<boolean>(false);
    const [is_active, setIs_active] = useState<boolean>(true);

    const [webhooksUri, setWebhooksUri] = useState<string>('');

    const [hoursStart, setHoursStart] = useState<string>('');
    const [hoursEnd, setHoursEnd] = useState<string>('');
    const [minutesStart, setMinutesStart] = useState<string>('');
    const [minutesEnd, setMinutesEnd] = useState<string>('');

    const [openAmo, setOpenAmo] = useState<boolean>(false);
    const [openBitrix, setOpenBitrix] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const [step, setStep] = useState<number>(0);

    const { brandId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const brandState = (useAppSelector((state) => state.mainState.brand))[brandId || ''];

    const compliteTime = (hh: string, mm: string) => {
        if (hh && mm) {
            return hh + ':' + mm + ':00'
        } else {
            return null
        }
    }

    const getIntervals = () => {
        if (brandId) {
            PushesAPI.getPushes(brandId)
                .then(response => {
                    if (response.status <= 204) {
                        setIntervals(response.data)
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const compliteState = (data: IBrandResponse) => {
        setCurrentBrand(data.brand);
        setName(data.brand.name);
        setAssistant_id(data.brand.assistant_id);
        setWazzup_api_key(data.brand.wazzup_api_key);
        setOpenai_api_key(data.brand.openai_api_key);
        setYclients_api_key(data.brand.yclients_api_key);
        setYclients_company_id(data.brand.yclients_company_id);
        setTokens(`${data.in_tokens} / ${data.out_tokens}`);
        setTurn_off_on_save(data.brand.turn_off_on_save);
        setIs_active(data.brand.is_active);
        
        if (data.brand.working_hours_start && data.brand.working_hours_end) {
            setHoursStart(data.brand.working_hours_start.split(':')[0]);
            setHoursEnd(data.brand.working_hours_end.split(':')[0]);
            setMinutesStart(data.brand.working_hours_start.split(':')[1]);
            setMinutesEnd(data.brand.working_hours_end.split(':')[1]);
        } else {
            setHoursStart('');
            setHoursEnd('');
            setMinutesStart('');
            setMinutesEnd('');
        }
    }

    const getWebhook = (token: string) => {
        ChannelsAPI.getWebhook(token)
            .then(response => {
                if (response.status <= 204) {
                    setWebhooksUri(response.data.webhooksUri || '');
                }
            })
    }

    const getBrand = (brandId: string) => {
        BrandsAPI.getBrand(brandId)
            .then(response => {
                if (response.status <= 204) {
                    compliteState(response.data);
                    if (response.data.brand.wazzup_api_key) {
                        getWebhook(response.data.brand.wazzup_api_key);
                    }
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
            })
            .catch((err) => {
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
    }

    const createBrand = () => {
        setIsDisabled(true);
        BrandsAPI.createBrand(
            {
                name,
                assistant_id,
                wazzup_api_key,
                openai_api_key,
                yclients_api_key,
                yclients_company_id,
                working_hours_start: compliteTime(hoursStart, minutesStart),
                working_hours_end: compliteTime(hoursEnd, minutesEnd),
                turn_off_on_save: turn_off_on_save,
            }
        )
        .then(response => {
            if (response.status <= 204) {
                dispatch(notificationActions.setMessage({message: 'Успешно'}));
                navigate(`brands`);
            } else {
                dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
            }
            setIsDisabled(false);
        })
        .catch((err) => {
            setIsDisabled(false);
            dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
        })
    }

    const editBrand = () => {
        if (brandId) {
            setIsDisabled(true);
            const data: IEditBrandRequest = {};
    
            currentBrand?.name !== name ? data.name = name : null;
            currentBrand?.assistant_id !== assistant_id ? data.assistant_id = assistant_id : null;
            currentBrand?.wazzup_api_key !== wazzup_api_key ? data.wazzup_api_key = wazzup_api_key : null;
            currentBrand?.openai_api_key !== openai_api_key ? data.openai_api_key = openai_api_key : null;
            currentBrand?.yclients_api_key !== yclients_api_key ? data.yclients_api_key = yclients_api_key : null;
            currentBrand?.yclients_company_id !== yclients_company_id ? data.yclients_company_id = yclients_company_id : null;
            currentBrand?.turn_off_on_save !== turn_off_on_save ? data.turn_off_on_save = turn_off_on_save : null;
            currentBrand?.is_active !== is_active ? data.is_active = is_active : null;
            if (
                (
                    currentBrand?.working_hours_start !== compliteTime(hoursStart, minutesStart) ||
                    currentBrand?.working_hours_end !== compliteTime(hoursEnd, minutesEnd)
                ) && (
                    compliteTime(hoursStart, minutesStart) && compliteTime(hoursEnd, minutesEnd)
                )
            ) {
                data.working_hours_start = compliteTime(hoursStart, minutesStart);
                data.working_hours_end = compliteTime(hoursEnd, minutesEnd);
            }
        
            BrandsAPI.editBrand(brandId, data)
                .then(response => {
                    if (response.status <= 204) {
                        navigate('/brands')
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                    setIsDisabled(false);
                })
                .catch((err) => {
                    setIsDisabled(false);
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const handleSave = () => {
        if (brandId) {
            editBrand();
        } else {
            createBrand()
        }
    }

    const handleAddInterval = () => {
        if (brandId) {
            PushesAPI.createPushes(brandId, Number(interval))
                .then(response => {
                    if (response.status <= 204) {
                        setInterval('');
                        setIsAddInterval(false);
                        getIntervals();
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const handleDeleteInterval = (pushId: string) => {
        if (brandId) {
            PushesAPI.deletePushes(brandId, pushId)
                .then(response => {
                    if (response.status <= 204) {
                        getIntervals();
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    useEffect(() => {
        if (brandId) {
            getBrand(brandId);
            getIntervals();
        }
    }, [brandId])

    return (
        <div className={styles.body}>
            {brandId &&
                <div className={styles.steps}>
                    <Steps
                        active={step}
                        onChange={setStep}
                        steps={stepItems}
                    />
                </div>
            }
            {step === 0 &&
                <>
                    <div className={styles.inputBlock}>
                        <CustomInput
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            placeholder='Название'
                            // disabled={!!brandId}
                        />
                        <CustomInput
                            value={assistant_id}
                            onChange={(e) => setAssistant_id(e.target.value)}
                            placeholder='Assistant ID'
                            // disabled={!!brandId}
                        />
                        <CustomInput
                            value={wazzup_api_key}
                            onChange={(e) => setWazzup_api_key(e.target.value)}
                            placeholder='Wazzup API key'
                            // disabled={!!brandId}
                        />
                        <CustomInput
                            value={openai_api_key}
                            onChange={(e) => setOpenai_api_key(e.target.value)}
                            placeholder='Openai API key'
                            // disabled={!!brandId}
                        />
                        <CustomInput
                            value={yclients_api_key}
                            onChange={(e) => setYclients_api_key(e.target.value)}
                            placeholder='Yclients API key'
                            // disabled={!!brandId}
                        />
                        <CustomInput
                            value={yclients_company_id}
                            onChange={(e) => setYclients_company_id(e.target.value)}
                            placeholder='Yclients Company ID'
                            // disabled={!!brandId}
                        />
                        {webhooksUri &&
                            <CustomInput
                                value={webhooksUri}
                                onChange={(e) => setWebhooksUri(e.target.value)}
                                placeholder='Webhooks Uri'
                                disabled
                            />
                        }
                        <CustomToggle
                            handleClick={setTurn_off_on_save}
                            check={turn_off_on_save}
                            id='turn_off_on_save'
                            nameLabel={turn_off_on_save ? 'Вкл' : 'Выкл'}
                            label='Отключение ИИ при сохранении данных'
                        />
                        {brandId &&
                            <CustomToggle
                                handleClick={setIs_active}
                                check={is_active}
                                id='turn_off_on_save'
                                nameLabel={is_active ? 'Активен' : 'Выключен'}
                                label='Статус бренда'
                            />
                        }
                    </div>
                    {brandId &&
                        <div className={styles.settingButtons}>
                            <MyButton handleClickProps={() => setOpenAmo(true)}>
                                Настроить AMO
                            </MyButton>
                            <MyButton handleClickProps={() => setOpenBitrix(true)}>
                                Настроить Bitrix
                            </MyButton>
                        </div>
                    }
                    <div className={styles.rightBlock}>
                        Время работы
                        <div className={styles.timeBlock}>
                            <div className={styles.timeItem}>
                                с
                                <div className={styles.inputTime} style={{marginLeft: '5px'}}>
                                    <CustomSelect
                                        selectedId={hoursStart}
                                        onChangeMethod={setHoursStart}
                                        options={hours}
                                        // disabled={!!brandId}
                                    />
                                </div>
                                :
                                <div className={styles.inputTime}>
                                    <CustomSelect
                                        selectedId={minutesStart}
                                        onChangeMethod={setMinutesStart}
                                        options={minutes}
                                        // disabled={!!brandId}
                                    />
                                </div>
                            </div>
                            <div className={styles.timeItem}>
                                до
                                <div className={styles.inputTime} style={{marginLeft: '-2px'}}>
                                    <CustomSelect
                                        selectedId={hoursEnd}
                                        onChangeMethod={setHoursEnd}
                                        options={hours}
                                        // disabled={!!brandId}
                                    />
                                </div>
                                :
                                <div className={styles.inputTime}>
                                    <CustomSelect
                                        selectedId={minutesEnd}
                                        onChangeMethod={setMinutesEnd}
                                        options={minutes}
                                        // disabled={!!brandId}
                                    />
                                </div>
                            </div>
                        </div>
                        {!!brandId &&
                            <>
                                <div className={styles.titleIntervals}>
                                    Интервалы дожимных сообщений (минуты)
                                </div>
                                <div className={styles.intervalsBlock}>
                                    {
                                        intervals.map(it => (
                                            <div className={styles.interval}>
                                                <CustomInput
                                                    value={it.interval}
                                                    placeholder=''
                                                />
                                                <MyButton 
                                                    handleClickProps={() => handleDeleteInterval(it.id)}
                                                    className={styles.deleteBtn}
                                                >
                                                    Удалить
                                                </MyButton>
                                            </div>
                                        ))
                                    }
                                    {isAddInterval &&
                                        <div className={styles.interval}>
                                            <CustomInput
                                                value={interval}
                                                type='number'
                                                onChange={(e) => setInterval(e.target.value)}
                                                placeholder=''
                                            />
                                            <MyButton 
                                                disabled={!interval} 
                                                handleClickProps={handleAddInterval}
                                            >
                                                Сохранить
                                            </MyButton>
                                        </div>
                                    }
                                    {!isAddInterval &&
                                        <div>
                                            <MyButton
                                            disabled={false}
                                                handleClickProps={() => setIsAddInterval(true)}
                                            >
                                                Добавить
                                            </MyButton>
                                        </div>
                                    }
                                </div>
                            </>
                        }
                    </div>
                </>
            }
            {step === 1 &&
                <Channels/>
            }
            {step === 2 &&
                <Scenarios/>
            }
            {openAmo &&
                <ModalContent
                    cross={true}
                    handleClose={() => setOpenAmo(false)}
                >
                    <Amo handleClose={() => setOpenAmo(false)}/>
                </ModalContent>
            }
            {openBitrix &&
                <ModalContent
                    cross={true}
                    handleClose={() => setOpenBitrix(false)}
                >
                    <Bitrix handleClose={() => setOpenAmo(false)}/>
                </ModalContent>
            }
            <div className={styles.saveBtnContainer}>
                <MyButton 
                    disabled={!brandId && (!name || !assistant_id || !wazzup_api_key || !openai_api_key || isDisabled)} 
                    handleClickProps={handleSave}
                >
                    Сохранить изменения
                </MyButton>
            </div>
        </div>
    )
}

export default BrandPage;
