import { AxiosResponse } from 'axios';
import Instance from './instance';
import ISubscription from 'src/models/ISubscription';

const instance: any = new Instance().getAxiosIns();

class SubscriptionsAPI {
    public static getSubscriptions(channel_id: string): Promise<AxiosResponse<ISubscription[]>> {
        return instance.get(`/subscription`, {
            params: {
                channel_id
            }
        });
    }
    
    public static editSubscriptions(subscription_id: string, channel_id: string, is_activated: boolean): Promise<AxiosResponse<any>> {
        return instance.patch(`/subscription/${subscription_id}`, null, {
            params: {
                channel_id,
                is_activated,
            }
        });
    }

    public static deleteSubscriptions(subscription_id: string, channel_id: string): Promise<AxiosResponse<any>> {
        return instance.delete(`/subscription/${subscription_id}`, {
            params: {
                channel_id
            }
        });
    }
}

export default SubscriptionsAPI;
