import { AxiosResponse } from 'axios';
import IBrandsResponse from 'src/models/response/IBrandsResponse';
import IEditBrandRequest from 'src/models/request/IEditBrandRequest';
import IBrandResponse from 'src/models/response/IBrandResponse';
import Instance from './instance';
import IAmoRequest from 'src/models/request/IAmoRequest';
import IBitrixRequest from 'src/models/request/IBitrixRequest';

const instance: any = new Instance().getAxiosIns();

class BrandsAPI {
    public static getBrands(limit: number, offset: number, search_query: string = ''): Promise<AxiosResponse<IBrandsResponse>> {
        return instance.get(`/brands`, {
            params: {
                limit,
                offset,
                ...search_query ? {search_query} : {},
            }
        });
    }

    public static getBrand(id: string): Promise<AxiosResponse<IBrandResponse>> {
        return instance.get(`/brands/${id}`);
    }

    public static createBrand(body: IEditBrandRequest): Promise<AxiosResponse<any>> {
        return instance.post(`/brands`, body);
    }

    public static editBrand(id: string, body: IEditBrandRequest): Promise<AxiosResponse<any>> {
        return instance.patch(`/brands/${id}`, body);
    }

    public static createBrandAmo(id: string, body: IAmoRequest): Promise<AxiosResponse<any>> {
        return instance.patch(`/brands/${id}/amo`, body);
    }

    public static createBrandBitrix(id: string, body: IBitrixRequest): Promise<AxiosResponse<any>> {
        return instance.patch(`/brands/${id}/bitrix`, body);
    }
}

export default BrandsAPI;
