import axios, { AxiosResponse } from 'axios';
import IChannelsRespponse from 'src/models/response/IChannelsResponse';
import Instance from './instance';
import IChannelRequest from 'src/models/request/IChannelRequest';
import IChannel from 'src/models/IChannel';
import IEditChannelRequest from 'src/models/request/IEditChannelRequest';
import IWazzapChannelRespponse from 'src/models/response/IWazzapChannelResponse';

const instance: any = new Instance().getAxiosIns();

const axiosConfigWazzap = axios.create({
    baseURL: 'https://api.wazzup24.com/v3',
    validateStatus: (status) => status < 500,
    withCredentials: false,
});


class ChannelsAPI {
    public static getChannels(brandId: string, limit: number, offset: number): Promise<AxiosResponse<IChannelsRespponse>> {
        return instance.get(`/brands/${brandId}/channels`, {
            params: {
                limit,
                offset,
            }
        });
    }

    public static getChannel(brandId: string, channelId: string): Promise<AxiosResponse<IChannel>> {
        return instance.get(`/brands/${brandId}/channels/${channelId}`);
    }

    public static getWazzupChannels(token: string): Promise<AxiosResponse<IWazzapChannelRespponse[]>> {
        return axiosConfigWazzap.get('/channels', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }

    public static getWebhook(token: string): Promise<AxiosResponse<any>> {
        return axiosConfigWazzap.get('/webhooks', {
            headers: {
                Authorization: 'Bearer ' + token
            }
        });
    }

    public static createChannel(brandId: string, body: IChannelRequest): Promise<AxiosResponse<any>> {
        return instance.post(`/brands/${brandId}/channels`, body);
    }

    public static editChannel(brandId: string, channelId: string, body: IEditChannelRequest): Promise<AxiosResponse<any>> {
        return instance.patch(`/brands/${brandId}/channels/${channelId}`, body);
    }
}

export default ChannelsAPI;
