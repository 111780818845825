import { MyButton } from '../ui/myButton'
import style from './style.module.scss'

interface IProps {
    handleOk: () => void,
    handleClose: () => void,
    text: string,
}

const Modal = ({handleOk, handleClose, text}: IProps) => {

    const handler = (e: any, method: () => void) => {
        e.stopPropagation();
        method();
    }

    return (
        <div className={style.overlay} onClick={handleClose}>
            <div className={style.container}>
                <div>{text}</div>
                <div className={style.buttons}>
                    <MyButton handleClickProps={(e: any) => handler(e, handleClose)}>
                        Отмена
                    </MyButton>
                    <MyButton handleClickProps={(e: any) => handler(e, handleOk)}>
                        Ок
                    </MyButton>
                </div>
            </div>
        </div>
    )
}

export default Modal
