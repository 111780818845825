import { AxiosResponse } from 'axios';
import IDialoguesResponse from 'src/models/response/IDialoguesResponse';
import Instance from './instance';
import IDialog from 'src/models/IDialog';

const instance: any = new Instance().getAxiosIns();

class DialoguesAPI {
    public static getDialogues(brandId: string, channelId: string, limit: number, offset: number, search_query: string): Promise<AxiosResponse<IDialoguesResponse>> {
        return instance.get(`/brands/${brandId}/channels/${channelId}/dialogues`, {
            params: {
                limit,
                offset,
                search_query,
            }
        });
    }
    
    public static getDialogue(brandId: string, channelId: string, dialogueId: string): Promise<AxiosResponse<IDialog>> {
        return instance.get(`/brands/${brandId}/channels/${channelId}/dialogues/${dialogueId}`);
    }

    public static createDialog(brandId: string, channelId: string, phone_number: string, lead_info: string): Promise<AxiosResponse<any>> {
        return instance.post(`/brands/${brandId}/channels/${channelId}/dialogues`, {
            lead_info,
            ...phone_number ? {phone_number} : {},
            // ...telegram_username ? {telegram_username} : {},
        });
    }

    public static loadFile(brandId: string, channelId: string, file: any): Promise<AxiosResponse<any>> {
        console.log(file)
        return instance.post(`/brands/${brandId}/channels/${channelId}/dialogues/broadcast`, file, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
    }

    public static editDialog(brandId: string, channelId: string, dialogue_id: string, is_active: boolean): Promise<AxiosResponse<any>> {
        return instance.patch(`/brands/${brandId}/channels/${channelId}/dialogues/${dialogue_id}`, {is_active});
    }

    public static deleteDialog(brandId: string, channelId: string, dialogue_id: string): Promise<AxiosResponse<any>> {
        return instance.delete(`/brands/${brandId}/channels/${channelId}/dialogues/${dialogue_id}`);
    }
}

export default DialoguesAPI;
