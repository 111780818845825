import classNames from 'classnames';
import style from './style.module.scss';
import { useState } from 'react';

interface Props {
    check: boolean;
    handleClick: (val: boolean) => void;
    id: string;
    label?: string;
    nameLabel?: string;
    disabled?: boolean;
}

export const CustomToggle = ({ check, handleClick, id = '', label, nameLabel, disabled }: Props) => {

    const switchToggle = () => {
        handleClick(!check);
    }

    return (
        <div className={classNames(style.container, {[style.withoutlable]: !label})}>
            <label className={style.switch}>
                <input key={id} type='checkbox' checked={check} onChange={switchToggle}/>
                <span className={style.slider}></span>
            </label>
            <span className={style.nameLabel}>{nameLabel}</span>
            {label &&
                <div className={style.lable}>
                    {label}:
                </div>
            }
        </div>
    );
};
