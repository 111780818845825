import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import PaginationComponent from 'src/components/ui/PaginationComponent';
import { useAppDispatch, useAppSelector } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import IBrand from 'src/models/IBrand';
import BrandsAPI from 'src/api/BrandsAPI';
import { useNavigate } from 'react-router-dom';
import { MyButton } from 'src/components/ui/myButton';
import TableMain from 'src/components/table';
import { CustomSearch } from 'src/components/ui/customSearch';
import BrandPage from './brandPage';
import { CrossIcon, DeleteIcon, PenclIcon } from 'src/assets/icons/icons';
import Modal from 'src/components/modal';
import mainActions from 'src/store/actions/mainActions';
import classNames from 'classnames';

const Brands = () => {
        
    // const brandsState = useAppSelector((state) => state.mainState.brands);

    const [brands, setBrands] = useState<IBrand[]>([]);
    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [editId, setEditId] = useState<string>('');

    const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

    const [searchValue, setSearchValue] = useState<string>('');

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    let click = 0;
    let timer: any = null;

    const getBrands = (limit: number, offset: number, search_query?: string) => {
        BrandsAPI.getBrands(limit, offset, search_query)
            .then(response => {
                if (response.status <= 204) {
                    setBrands(response.data.brands);
                    setCount(response.data.amount);
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
            })
            .catch((err) => {
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
    }

    const handleSetCurrent = (brand: IBrand) => {
        dispatch(mainActions.setCurrentBrand(brand));
    }

    const handleClick = (brand?: IBrand) => {

        click++;

        if(click === 1) {

            timer = setTimeout(function() {
                if (brand?.id) {
                    navigate(`/brand/${brand.id}`);
                    dispatch(mainActions.setCurrentBrand(brand));
                } else {
                    navigate(`/brand`);
                }
                click = 0;           

            }, 500);

        } else {

            clearTimeout(timer);   
            brand ? handleSetCurrent(brand) : null;
            click = 0;             
        }
    }

    const handleSearch = (value: string) => {
        setSearchValue(value);
        getBrands(20, 0, value)
    }

    const handleDelete = () => {
        BrandsAPI.editBrand(editId, {
            turn_off_on_save: false,
        })
        .then(response => {
            if (response.status <= 204) {
                setIsOpenDelete(false);
            } else {
                dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
            }
        })
        .catch((err) => {
            dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
        })
    }

    useEffect(() => {
        getBrands(20, 0);
    }, []);

    return (
        <div className={styles.body}>
            <div className={styles.buttonContainer}>
                <MyButton handleClickProps={() => handleClick()}>+ Создать бренд</MyButton>
            </div>
            <div className={styles.searchContainer}>
                <CustomSearch
                    onChangeMethod={handleSearch}
                    placeholder='Поиск'
                />
            </div>
            <div>
                <TableMain>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Название бренда</th>
                            <th>Статус</th>
                        </tr>
                    </thead>
                    <tbody className={styles['']}>
                        {brands.map((item, index) => (
                            <tr 
                                className={styles.tableTr} 
                                key={item.id} onClick={() => handleClick(item)}
                                onDoubleClick={(e) => e.preventDefault()}
                            >
                                <td>
                                    <div className={styles.tdTextName}>{(page-1)*20+index+1}</div>
                                </td>
                                <td>
                                    <div className={styles.tdTextName}>{item.name}</div>
                                </td>
                                <td>
                                    <div className={classNames(styles.tdTextName, styles.status, {[styles.active]: item.is_active})}>
                                        {item.is_active ? 'Активен' : 'Выключен'}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </TableMain>
                {count > 20 &&
                    <PaginationComponent
                        count={count}
                        limit={20}
                        page={page}
                        setPage={setPage}
                        onChange={getBrands}
                    />
                }
            </div>
            {isOpenDelete &&
                <Modal
                    handleClose={() => setIsOpenDelete(false)}
                    handleOk={handleDelete}
                    text='Удалить бренд?'
                />
            }
        </div>
    )
}

export default Brands;
