import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import BrandsAPI from 'src/api/BrandsAPI';
import ChannelsAPI from 'src/api/ChannelsAPI';
import IBrand from 'src/models/IBrand';
import IChannel from 'src/models/IChannel';

const mainActions = {
    getBrands: createAsyncThunk('getBrands', async () => {
        const data = (await BrandsAPI.getBrands(20, 0))?.data;
        return {
            data,
        };
    }),
    getBrand: createAsyncThunk('getBrand', async (id: string) => {
        const data = (await BrandsAPI.getBrand(id))?.data;
        return {
            data,
        };
    }),
    getChannels: createAsyncThunk('getChannels', async (brandId: string) => {
        const data = (await ChannelsAPI.getChannels(brandId, 20, 0))?.data;
        return {
            data,
            brandId,
        };
    }),
    addTab: createAction('addTab', (payload: {link: string ,name: string, id: string, fullName: string}) => {
        return {
            payload,
        };
    }),
    closeTab: createAction('closeTab', (payload: {id: string}) => {
        return {
            payload,
        };
    }),
    setActiveTab: createAction('setActiveTab', (payload: {id: string}) => {
        return {
            payload,
        };
    }),
    clearState: createAction('clearState', () => {
        return {
            payload: '',
        };
    }),
    setCurrentBrand: createAction('setCurrentBrand', (payload: IBrand | null) => {
        return {
            payload,
        };
    }),
    setCurrentChannel: createAction('setCurrentChannel', (payload: IChannel | null) => {
        return {
            payload,
        };
    }),
};

export default mainActions;
