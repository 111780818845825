import { CustomInput } from 'src/components/ui/customInput'
import styles from './style.module.scss'
import { MyButton } from 'src/components/ui/myButton'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import ChannelsAPI from 'src/api/ChannelsAPI';
import notificationActions from 'src/store/actions/notificationActions';
import PaginationComponent from 'src/components/ui/PaginationComponent';
import IChannelsRespponse from 'src/models/response/IChannelsResponse';
import IChannel from 'src/models/IChannel';
import { CustomSelect } from 'src/components/ui/customSelect';
import ChatTypesEnum from 'src/models/enums/ChatTypesEnum';
import TableMain from 'src/components/table';
import { CrossIcon, PenclIcon } from 'src/assets/icons/icons';
import ChannelPage from './scenarioPage';
import IScenario from 'src/models/IScenario';
import ScenarioAPI from 'src/api/ScenarioAPI';
import ScenarioPage from './scenarioPage';

const Scenarios = () => {
    const [scenarios, setScenarios] = useState<IScenario[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>('');
    
    const { brandId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const getScenarios = (limit: number, offset: number) => {
        if (brandId) {
            ScenarioAPI.getScenarios(brandId, limit, offset)
                .then(response => {
                    if (response.status <= 204) {
                        setScenarios(response.data.scenarios);
                        setCount(response.data.amount);
                    }
                })
        }
    }

    const handleClick = (id?: string) => {
        if (id) {
            navigate(`/brand/${brandId}/scenario/${id}`);
        } else {
            navigate(`/brand/${brandId}/scenario`);
        }
    }

    const handleEdit = () => {
        setIsEdit(false);
        getScenarios(20, 0);
        setCount(1);
        setEditId('');
        setIsEditing(false);
    }

    const handleClose = () => {
        setEditId('');
        setIsEdit(false);
        setIsEditing(false);
    }

    useEffect(() => {
        if (brandId && brandId !== 'all') {
            getScenarios(20, 0);
        }
    }, [brandId])

    return (
        <div className={styles.channelsBlock}>
            {/* <div className={styles.tokens}>
                Токены: {}
            </div> */}
            {/* <div className={styles.buttonContainer}>
                <MyButton plus handleClickProps={() => handleClick()}></MyButton>
            </div> */}
            <div>
                <TableMain>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Название</th>
                        </tr>
                    </thead>
                    <tbody className={styles['']}>
                        {scenarios.map((item, index) => (
                            <tr 
                                className={styles.tableTr} 
                                key={item.id} 
                                onClick={() => handleClick(item.id)} 
                            >
                                <td>
                                    <div className={styles.tdTextName}>{(page-1)*20+index+1}</div>
                                </td>
                                <td>
                                    <div className={styles.tdTextName}>{item.name}</div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </TableMain>

                {count > 20 &&
                    <PaginationComponent
                        count={count}
                        limit={20}
                        page={page}
                        setPage={setPage}
                        onChange={getScenarios}
                    />
                }
            </div>
        </div>
    )
}

export default Scenarios;
