import { Check } from 'src/assets/icons/icons';
import style from './style.module.scss';
import classNames from 'classnames';

interface CustomCheckboxPropsTypes {
    nameLabel?: string;
    key?: number;
    handleChecked?: (checked: boolean) => void;
    checked?: boolean;
    disabled?: boolean;
}

export const CircleCheckbox = (props: CustomCheckboxPropsTypes) => {
    const { nameLabel, handleChecked, checked = false, disabled = false } = props;

    const handleCheckboxClick = () => {
        if (handleChecked) handleChecked(!checked);
    };

    return (
        <label className={classNames(style.container, {[style.disabled]: disabled})}>
            <input className={style.checkboxNoneStyle} disabled={disabled} type='checkbox' checked={checked} onChange={handleCheckboxClick} />
            <div className={style.checkmark}>
            </div>
            <span className={style.nameLabel}>{nameLabel}</span>
        </label>
    );
};
