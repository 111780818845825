import { Alert, createTheme, Snackbar, ThemeProvider } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import Router from './router';
import './style.css'

function App() {
    const message = useAppSelector((state) => state.notificationState.message);

    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(notificationActions.setMessage({message: ''}));
    }

    const theme = createTheme({
        palette: {
          primary: {
            light: "#1718FF",
            main: "#1718FF",
            dark: "#1718FF",
          },
        },
      });

    return (
        <div>
            <ThemeProvider theme={theme}>
                <Router />
                <Snackbar 
                    open={!!message} 
                    autoHideDuration={3000} 
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert
                        onClose={handleClose}
                        severity={message === 'Успешно' ? "success" : message === '' ? "info" : "error"}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </ThemeProvider>
        </div>
    );
}

export default App;
