import { CustomInput } from 'src/components/ui/customInput'
import styles from './style.module.scss'
import { MyButton } from 'src/components/ui/myButton'
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import ChannelsAPI from 'src/api/ChannelsAPI';
import PaginationComponent from 'src/components/ui/PaginationComponent';
import IChannel from 'src/models/IChannel';
import TableMain from 'src/components/table';
import wh from '../../assets/icons/wh.svg';
import vk from '../../assets/icons/vk.svg';
import vi from '../../assets/icons/vi.svg';
import av from '../../assets/icons/av.svg';
import ins from '../../assets/icons/in.svg';
import te from '../../assets/icons/te.svg';
import classNames from 'classnames';

const chatTypes = [
    {id: 'whatsapp', name: 'whatsapp'},
    {id: 'whatsgroup', name: 'whatsgroup'},
    {id: 'viber', name: 'viber'},
    {id: 'instagram', name: 'instagram'},
    {id: 'telegram', name: 'telegram'},
    {id: 'vk', name: 'vk'},
    {id: 'avito', name: 'avito'},
    {id: 'telegroup', name: 'telegroup'},
]

const chanImages: any = {
    whatsapp: wh,
    viber: vk,
    instagram: ins,
    telegram: te,
    vk: vk,
    avito: av,
}

const amoEnabled = [
    {id: 'false', name: 'выкл'},
    {id: 'true', name: 'вкл'},
]

const Channels = () => {
    const [channels, setChannels] = useState<IChannel[]>([]);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const [count, setCount] = useState<number>(0);
    const [page, setPage] = useState<number>(1);

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [editId, setEditId] = useState<string>('');
    
    const { brandId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const getChannels = (limit: number, offset: number) => {
        if (brandId) {
            ChannelsAPI.getChannels(brandId, limit, offset)
                .then(response => {
                    if (response.status <= 204) {
                        setChannels(response.data.channels);
                        setCount(response.data.amount);
                    }
                })
        }
    }

    const handleClick = (id?: string) => {
        setIsEdit(true);
        if (id) {
            navigate(`/brand/${brandId}/channel/${id}`);
        } else {
            navigate(`/brand/${brandId}/channel`);
        }
    }

    const handleEdit = () => {
        setIsEdit(false);
        getChannels(20, 0);
        setCount(1);
        setEditId('');
        setIsEditing(false);
    }

    const handleClose = () => {
        setEditId('');
        setIsEdit(false);
        setIsEditing(false);
    }

    useEffect(() => {
        if (brandId && brandId !== 'all') {
            getChannels(20, 0);
        }
    }, [brandId])

    return (
        <div className={styles.channelsBlock}>
            <div className={styles.buttonContainer}>
                <MyButton 
                    handleClickProps={() => handleClick()}
                    disabled={brandId === 'all'}
                >
                    + Создать канал
                </MyButton>
            </div>
            <div>
                <TableMain>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Название</th>
                            <th>ID</th>
                            <th>Задержка</th>
                            <th>AMO</th>
                            <th>Bitrix</th>
                            <th>Тип</th>
                        </tr>
                    </thead>
                    <tbody className={styles['']}>
                        {channels.map((item, index) => (
                            <tr className={styles.tableTr} key={item.id} onClick={() => handleClick(item.id)}>
                                <td>
                                    <div className={styles.tdTextName}>{(page-1)*20+index+1}</div>
                                </td>
                                <td>
                                    <div className={styles.tdTextName}>{item.name}</div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {item.id}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {item.answer_delay}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {item.amo_is_enabled ? 'Активный' : 'Выключен'}
                                    </div>
                                </td>
                                <td>
                                    <div className={styles.tdText}>
                                        {item.bitrix_is_enabled ? 'Активный' : 'Выключен'}
                                    </div>
                                </td>
                                <td>
                                    <div className={classNames(styles.tdText, styles.imgContainer)}>
                                        <img src={chanImages[item.chat_type] || ''} alt="" />
                                        {item.chat_type}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </TableMain>

                {count > 20 &&
                    <PaginationComponent
                        count={count}
                        limit={20}
                        page={page}
                        setPage={setPage}
                        onChange={getChannels}
                    />
                }
            </div>
        </div>
    )
}

export default Channels;
