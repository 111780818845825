import { useRef, useState } from 'react'
import styles from './style.module.scss'
import { MyButton } from '../myButton'

interface IProps {
    value: any
    onChange: any
}

const FilePickerInput = ({value, onChange}: IProps) => {

    const fileRef: any = useRef(null);

    const handleFileClick = (event: any) => {
        const fileReader = new FileReader();
        fileReader.addEventListener('load', () => {
            // onChange(fileReader.result)
            onChange(event.target.files[0])
        });
        fileReader.readAsArrayBuffer(event.target.files[0] || null);
        // fileReader.readAsDataURL(event.target.files[0]);
    };

    const handleButtonClick = () => {
        fileRef.current.click()
    }

    return (
        <div className={styles.container}>
            <input
                className={styles.hidden}
                type="file" 
                ref={fileRef}
                onChange={handleFileClick}
            />
            <MyButton 
                handleClickProps={handleButtonClick}
            >
                Выбрать файл
            </MyButton>
            <span>{value?.name || 'Файл не выбран'}</span>
        </div>
    )
}

export default FilePickerInput
