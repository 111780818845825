import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import { useNavigate, useParams } from 'react-router-dom';
import { MyButton } from 'src/components/ui/myButton';
import SubscriptionsAPI from 'src/api/SubscriptionsAPI';
import ISubscription from 'src/models/ISubscription';
import moment from 'moment';
import Modal from 'src/components/modal';
import classNames from 'classnames';
import TableMain from 'src/components/table';

const Subscriptions = () => {
    const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [deletedSub, setDeletedSub] = useState<string>('');
    
    const { brandId, channelId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const getSubscriptions = () => {
        if (channelId && channelId !== 'all') {
            SubscriptionsAPI.getSubscriptions(channelId)
                .then(response => {
                    if (response.status <= 204) {
                        setSubscriptions(response.data);
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const setActivated = (subscriptionId: string, isActivated: boolean) => {
        if (channelId) {
            SubscriptionsAPI.editSubscriptions(subscriptionId, channelId, isActivated)
                .then(response => {
                    if (response.status <= 204) {
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                        getSubscriptions();
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const handleDelete = (id: string) => {
        setDeletedSub(id);
        setIsOpenModal(true);
    }

    const deleteSubscription = (id: string) => {
        if (channelId) {
            SubscriptionsAPI.deleteSubscriptions(id, channelId)
                .then(response => {
                    if (response.status <= 204) {
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                        getSubscriptions();
                        setIsOpenModal(false);
                        setDeletedSub('');
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const handleClose = () => {
        setIsOpenModal(false);
        setDeletedSub('');
    }

    const handleOk = () => {
        deleteSubscription(deletedSub);
    }

    useEffect(() => {
        getSubscriptions();
    }, [brandId, channelId]);

    return (
        <div className={styles.body}>
            {/* <h3>Заявки на уведомления</h3> */}
            <div>
                <TableMain>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>ID чата</th>
                            <th>Никнейм</th>
                            <th>Дата</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody className={styles['']}>
                        {subscriptions.map((item, index) => (
                            <tr className={styles.tableTr} key={item.id}>
                                <td>
                                    <div className={styles.tdTextName}>{index+1}</div>
                                </td>
                                <td>
                                    <div className={styles.tdTextName}>{item.chat_id}</div>
                                </td>
                                <td>
                                    <div className={styles.tdTextName}>{item.user_name || '-'}</div>
                                </td>
                                <td>
                                    <div className={styles.tdTextName}>{moment(item.created_at).format('DD-MM-YYYY hh:mm')}</div>
                                </td>
                                <td className={styles.buttonsTd}>
                                    <div className={classNames(styles.tdTextName, styles.buttons)}>
                                        {/* <div className={styles.buttons}> */}
                                            <MyButton handleClickProps={() => setActivated(item.id, !item.is_activated)}>
                                                {item.is_activated ? 'Деактивировать' : 'Активировать'}
                                            </MyButton>
                                            <MyButton className={styles.redButton} handleClickProps={() => handleDelete(item.id)}>
                                                Удалить
                                            </MyButton>
                                        {/* </div> */}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </TableMain>
            </div>
            {isOpenModal &&
                <Modal
                    handleClose={handleClose}
                    handleOk={handleOk}
                    text='Удалить заявку?'
                />
            }
        </div>
    )
}

export default Subscriptions;
