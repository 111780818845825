import { CustomInput } from 'src/components/ui/customInput';
import style from './style.module.scss';
import { MyButton } from 'src/components/ui/myButton';
import FilePicker from 'src/components/ui/filePicker';
import DialoguesAPI from 'src/api/DialoguesAPI';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';

const CreateDialogue = () => {

    const [phone_number, setPhone_number] = useState<string>('');
    const [lead_info, setLead_info] = useState<string>('');
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const [file, setFile] = useState<any>(null);
    
    const { brandId, channelId, dialogueId } = useParams();
    
    const dispatch = useAppDispatch();

    const handlePhoneChange = (val: string) => {
        const regex = /[a-z]|[A-Z]|[а-я]|[А-Я]/;

        if (!regex.test(val)) {
            setPhone_number(val);
        } else {
            setPhone_number(phone_number);
        }
    };

    const handleSaveFile = async () => {
        const data = new FormData();

        data.append('file', file);

        if (brandId && channelId) {
            setIsDisabled(true);
            DialoguesAPI.loadFile(brandId, channelId, data)
                .then(response => {
                    if (response.status <= 204) {
                        setFile(null);
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    }else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                    setIsDisabled(false);
                })
                .catch(err => {
                    setIsDisabled(false);
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const handleSaveDialogue = () => {
        if (brandId && channelId) {
            setIsDisabled(true);
            DialoguesAPI.createDialog(brandId, channelId, phone_number, lead_info)
                .then(response => {
                    if (response.status <= 204) {
                        setPhone_number('');
                        setLead_info('');


                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                    setIsDisabled(false);
                })
                .catch((err) => {
                    setIsDisabled(false);
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }
    
    return (
        <div>
            <div className={style.creationBlock}>
                <div className={style.title}>Добавить диалог</div>
                <div className={style.info}>*работает только с whatsapp и личными аккаунтами telegram</div>
                <div className={style.inputContainer}>
                    <CustomInput
                        value={phone_number}
                        onChange={(e) => setPhone_number(e.target.value)}
                        placeholder='Номер телефона/Никнейм'
                    />
                    <CustomInput
                        value={lead_info}
                        onChange={(e) => setLead_info(e.target.value)}
                        placeholder='Описание'
                    />
                    <MyButton 
                        disabled={!phone_number || !lead_info || isDisabled} 
                        handleClickProps={handleSaveDialogue}
                    >
                        Сохранить изменения
                    </MyButton>
                </div>
            </div>
            <div className={style.fileBlock}>
                <div className={style.title}>Загрузить файл</div>
                <div className={style.inputContainer}>
                    <FilePicker
                        value={file}
                        onChange={setFile}
                    />
                    <MyButton 
                        disabled={!file || isDisabled} 
                        handleClickProps={handleSaveFile}
                    >
                        Загрузить
                    </MyButton>
                </div>
            </div>
        </div>
    )
}

export default CreateDialogue
