import { CustomInput } from 'src/components/ui/customInput';
import style from './style.module.scss';
import CustomTextarea from 'src/components/ui/customTextArea';
import { useState } from 'react';
import { MyButton } from 'src/components/ui/myButton';
import FilePickerInput from 'src/components/ui/filePickerInput';
import KnowledgeAPI from 'src/api/KnowledgeAPI';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';

const KnowledgePage = () => {
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [file, setFile] = useState<any>(null);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const { brandId, knowledgeId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const createFile = (fileUrl: string) => {
        if (brandId && knowledgeId) {
            KnowledgeAPI.createFile(brandId, knowledgeId, {
                name,
                description,
                s3_url: fileUrl
            })
            .then(response => {
                if (response.status <= 204) {
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    navigate(`/brand/${brandId}/knowledges`);
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
            })
            .catch((err) => {
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
        }
    }

    const loadFile = (url: string, fileUrl: string) => {
        KnowledgeAPI.addFileToS3(url, file, file.type)
            .then(response => {
                if (response.status <= 204) {
                    createFile(fileUrl);
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
            })
            .catch((err) => {
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
    }

    const handleSave = () => {
        if (brandId && knowledgeId) {
            setIsDisabled(true);
            KnowledgeAPI.signS3(brandId, knowledgeId)
                .then(response => {
                    if (response.status <= 204) {
                        loadFile(response.data.url, response.data.file);
                    } else {
                        dispatch(notificationActions.setMessage({message: 'Ошибка'}));
                    }
                    setIsDisabled(false);
                })
                .catch((err) => {
                    setIsDisabled(false);
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    return (
        <div className={style.container}>
            <div className={style.inputContainer}>
                <CustomInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Название'
                />
                <CustomTextarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder='Описание'
                />
                <FilePickerInput
                    value={file}
                    onChange={setFile}
                />
                <div className={style.btnContainer}>
                    <MyButton
                        disabled={!name || !file || isDisabled}
                        handleClickProps={handleSave}
                    >
                        Сохранить изменения
                    </MyButton>
                </div>
            </div>
        </div>
    )
}

export default KnowledgePage
