import { CustomInput } from 'src/components/ui/customInput';
import styles from './style.module.scss';
import { MyButton } from 'src/components/ui/myButton';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomSelect } from 'src/components/ui/customSelect';
import ScenarioAPI from 'src/api/ScenarioAPI';
import CustomTextarea from 'src/components/ui/customTextArea';
import Steps from './Steps';
import Slider from '@mui/material/Slider';
import './slider.css';
import { CircleCheckbox } from 'src/components/ui/circleCheckbox';
import { validate } from 'uuid';

const stepItems = ['Полный скрипт', 'Настройки моделей', 'Выбор функции '];
const modelsAI = [
    {name: "AiR", id: "gpt-4o"},
    {name: "AiR lite", id: "gpt-4o-mini"},
    {name: "AiR 2.0 smart", id: "gemini-2.0-flash"},
    {name: "AiR 2.0 smart-lite", id: "gemini-2.0-flash-lite"}
]
const marks = [
    {
      value: 0,
      label: '0.00',
    },
    {
      value: 200,
      label: '2.00',
    },
];
const marksTop = [
    {
      value: 0,
      label: '0.00',
    },
    {
      value: 100,
      label: '1.00',
    },
];

const ScenarioPage = () => {
    
    const [name, setName] = useState<string>('');
    const [model, setModel] = useState<string>('gpt-4o');
    const [temperature, setTemperature] = useState<number>(70);
    const [top, setTop] = useState<number>(100);
    const [save_user_data, setSave_user_data] = useState<boolean>(false);
    const [send_media, setSend_media] = useState<boolean>(false);
    const [prompt, setPrompt] = useState<string>('');
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const [step, setStep] = useState<number>(0);

    const currentBrandFromState = useAppSelector((state) => state.mainState.currentBrand);

    const {brandId, scenarioId} = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const parseJson = (json: any) => {
        setModel(json.single_agent_config.llm_config.model || '');
        setTemperature(json.single_agent_config.llm_config.temperature * 100 || 70);
        setTop(json.single_agent_config.llm_config.top * 100 || 100);
        setSave_user_data(json.single_agent_config.available_functions.includes('save_user_data') || false);
        setSend_media(json.single_agent_config.available_functions.includes('send_media') || false);
        setPrompt(json.single_agent_config.system_prompt || '');
    }

    const getScenario = () => {
        if (brandId && scenarioId) {
            ScenarioAPI.getScenario(brandId, scenarioId)
                .then(response => {
                    if (response.status <= 204) {
                        setName(response.data.name);
                        parseJson(response.data.json_object);
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const createJson = () => {
        const available_functions = [];
        send_media && available_functions.push('send_media');
        save_user_data && available_functions.push('save_user_data');

        const json = {
            interaction_mode: "single_agent",
            single_agent_config: {
                system_prompt: prompt,
                llm_config: {
                    model: model,
                    temperature: temperature/100,
                    top: top/100,
                },
                available_functions: available_functions,
            }
        }

        return json
    }

    const handleEditScenario = () => {
        if (brandId && scenarioId) {
            setIsDisabled(true);
            ScenarioAPI.editScenario(brandId, scenarioId, name, createJson())
                .then(response => {
                    if (response.status <= 204) {
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                        navigate(`/brand/${brandId}/scenarios`);
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                    setIsDisabled(false);
                })
                .catch((err) => {
                    setIsDisabled(false);
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const handleSaveScenario = () => {
        if (brandId) {
            setIsDisabled(true);
            ScenarioAPI.createScenario(brandId, name, createJson())
                .then(response => {
                    if (response.status <= 204) {
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                        navigate(`/brand/${brandId}/scenarios`);
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                    setIsDisabled(false);
                })
                .catch((err) => {
                    setIsDisabled(false);
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const validate = () => {
        if (!brandId) {
            dispatch(notificationActions.setMessage({message: 'Не выбран бренд'}));
            return false
        }
        return true
    }

    const handleSave = () => {
        if (!validate()) return

        setIsOpenModal(true);
    }

    const saveScenario = () => {
        if (scenarioId) {
            handleEditScenario();
        } else {
            handleSaveScenario();
        }
    }
    
    const valuetext = (value: number) => {
        return `${value/100}`;
    }

    const clearState = () => {
        setName('');
        setModel('');
        setTemperature(70);
        setSave_user_data(false);
        setSend_media(false);
        setPrompt('');
    }

    useEffect(() => {
        if (!scenarioId) {
            clearState();
        }
    }, [scenarioId])

    useEffect(() => {
        getScenario();
    }, []);

    return (
        <div className={styles.body}>
            <div className={styles.inputBlock}>
                {/* <CustomInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Название'
                    disabled={!!scenarioId && !isEditing}
                /> */}
                <Steps
                    active={step}
                    onChange={setStep}
                    steps={stepItems}
                />
                <h3>{stepItems[step]}</h3>
                {step === 0 &&
                    <CustomTextarea
                        value={prompt}
                        onChange={(v) => setPrompt(v.target.value)}
                    />
                }
                {step === 1 &&
                    <div className={styles.step_2}>
                        <div className={styles.subTitle}>Модель для полного промта (определение текущей фазы): </div>
                        <CustomSelect
                            options={modelsAI}
                            selectedId={model}
                            onChangeMethod={setModel}
                        />
                        <div className={styles.subTitle}>Настройки генерации</div>
                        <div className={styles.temperature}>
                            <p>Температура</p>
                            <Slider
                                aria-label="Custom marks"
                                defaultValue={100}
                                min={0} 
                                max={200}
                                value={temperature}
                                onChange={(e, v) => setTemperature(v as number)}
                                color="primary"
                                marks={marks}
                                valueLabelDisplay="on"
                                valueLabelFormat={valuetext}
                            />
                        </div>
                        <div className={styles.temperature}>
                            <p>Топ Р</p>
                            <Slider
                                aria-label="Custom marks"
                                defaultValue={100}
                                min={0} 
                                max={100}
                                value={top}
                                onChange={(e, v) => setTop(v as number)}
                                color="primary"
                                marks={marksTop}
                                valueLabelDisplay="on"
                                valueLabelFormat={valuetext}
                            />
                        </div>
                    </div>
                }
                {step === 2 &&
                    <div className={styles.step_3}>
                        <CircleCheckbox
                            nameLabel='Сохранение данных (save_user_data)'
                            checked={save_user_data}
                            handleChecked={setSave_user_data}
                        />
                        <CircleCheckbox
                            nameLabel='Отправка медиа файлов (send_media)'
                            checked={send_media}
                            handleChecked={setSend_media}
                        />
                    </div>
                }
                <div className={styles.btnContainer}>
                    <MyButton 
                        handleClickProps={handleSave}
                    >
                        Сохранить изменения
                    </MyButton>
                </div>
                {isOpenModal &&
                    <div className={styles.layout}>
                        <div className={styles.modalSave}>
                            <span>{scenarioId ? 'Сохранить сценарий?' : 'Название сценария'}</span>
                            <CustomInput
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            <div className={styles.buttonContainer}>
                                <MyButton
                                    handleClickProps={() => setIsOpenModal(false)}
                                >
                                    Отмена
                                </MyButton>
                                <MyButton
                                    handleClickProps={saveScenario}
                                    disabled={!name || isDisabled}
                                >
                                    Сохранить
                                </MyButton>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default ScenarioPage;
