import { Outlet, useNavigate, useParams } from 'react-router-dom';
import styles from './style.module.scss'
import { useEffect, useState } from 'react';
import isAuth from 'src/utils/isAuth';
import MainMenu from '../MainMenu';
import { useAppDispatch, useAppSelector } from 'src/store';
import classNames from 'classnames';
import mainActions from 'src/store/actions/mainActions';
import { AccountMainIcon, CrossIcon } from 'src/assets/icons/icons';
import { CustomSelectSearch } from '../ui/customSelectSearch';
import BrandsAPI from 'src/api/BrandsAPI';
import ChannelsAPI from 'src/api/ChannelsAPI';
import IBrand from 'src/models/IBrand';
import IChannel from 'src/models/IChannel';

const Layout = () => {
    const [isOpenExit, setIsOpenExit] = useState<boolean>(false);

    const [currentBrand, setCurrentBrand] = useState<string>('');
    const [currentChannel, setCurrentChannel] = useState<string>('');

    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    
    const { brandId, channelId } = useParams();
    
    const tabs = useAppSelector((state) => state.mainState.tabs);
    const activeTab = useAppSelector((state) => state.mainState.activeTab);
    const currentBrandFromState = useAppSelector((state) => state.mainState.currentBrand)?.id;
    const currentChannelFromState = useAppSelector((state) => state.mainState.currentChannel)?.id;

    const handleExit = () => {
        localStorage.clear();
        dispatch(mainActions.clearState());
        navigate('/auth');
    }

    const handleSearchBrandChange = (value: IBrand) => {
        setCurrentChannel('');
        setCurrentBrand(value.id);
        dispatch(mainActions.setCurrentBrand(value));
        dispatch(mainActions.setCurrentChannel(null));
        if (brandId) {
            const url = location.pathname.split('/');
            url[2] = value.id;
            navigate(url.join('/'));
        }
        if (channelId) {
            const url = location.pathname.split('/');
            url[4] = 'all';
            navigate(url.join('/'));
        }
    }

    const handleSearchChannelChange = (value: IChannel) => {
        setCurrentChannel(value.id);
        dispatch(mainActions.setCurrentChannel(value));
        if (channelId) {
            const url = location.pathname.split('/');
            url[4] = value.id;
            navigate(url.join('/'));
        }
    }

    const handleClick = (link: string, id: string) => {
        dispatch(mainActions.setActiveTab({id}))
        navigate(link);
    } 

    const handleCloseTab = (id: string, index: number, event: any) => {
        event.stopPropagation();
        if (id === activeTab) {
            if (tabs[index - 1]) {
                dispatch(mainActions.setActiveTab({id: tabs[index - 1].id}));
                navigate(tabs[index - 1].link);
            } else if (tabs[index + 1]) {
                dispatch(mainActions.setActiveTab({id: tabs[index + 1].id}));
                navigate(tabs[index + 1].link);
            } else {
                navigate('/brands');
            }
        }
        dispatch(mainActions.closeTab({id}));
    }

    useEffect(() => {
        if (!currentChannelFromState) {
            setCurrentChannel('');
        } else {
            setCurrentChannel(currentChannelFromState);
        }
    }, [currentChannelFromState]);

    useEffect(() => {
        if (!currentBrandFromState) {
            setCurrentBrand('');
            setCurrentChannel('');
        } else {
            setCurrentBrand(currentBrandFromState);
        }
    }, [currentBrandFromState]);

    useEffect(() => {
        if (!isAuth()) {
            navigate('/auth');
        }
        if (brandId && brandId !== 'all') {
            setCurrentBrand(brandId);
        }
        if (channelId && channelId !== 'all') {
            setCurrentChannel(channelId);
        }
    }, [])

    return (
        <div className={styles.layout}>
            <MainMenu/>
            {/* {!!tabs.length &&
                <div className={styles.tabsContainer}>
                    {
                        tabs.map((tab, index) => (
                            <div 
                                className={classNames(styles.tabItem, {
                                    [styles.activeTab]: activeTab === tab.id,
                                })} 
                                onClick={() => handleClick(tab.link, tab.id)}
                            >
                                {tab.name}
                                <div className={styles.cross} onClick={(e) => handleCloseTab(tab.id, index, e)}>
                                    <CrossIcon/>
                                </div>
                                <div className={styles.fullName}>
                                    {tab.fullName}
                                </div>
                            </div>
                        ))
                    }
                </div>
            } */}
            <div 
                className={styles.accountContainer} 
                onMouseMove={() => setIsOpenExit(true)}
                onMouseLeave={() => setIsOpenExit(false)}
            >
                <AccountMainIcon/>
                {isOpenExit &&
                    <div className={styles.exitButton} >
                        <span onClick={handleExit}>Выйти</span>
                    </div>
                }
            </div>
            <div className={styles.container}>
                <div className={styles.searchContainer}>
                    {location.pathname !== '/brand' &&
                        <CustomSelectSearch
                            getMethod={BrandsAPI.getBrands}
                            onChangeMethod={(v) => handleSearchBrandChange(v)}
                            name='brands'
                            selectedId={currentBrand}
                            placeholder='Бренд'
                        />
                    }
                    {location.pathname.includes('dialogue') && brandId && brandId !== 'all' &&
                        <CustomSelectSearch
                            getMethod={ChannelsAPI.getChannels}
                            onChangeMethod={(v) => handleSearchChannelChange(v)}
                            name='channels'
                            selectedId={currentChannel}
                            placeholder='Канал'
                            getParams={[brandId]}
                        />
                    }
                </div>
                <div className={styles.content}>
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Layout;
