import styles from './style.module.scss';
import classNames from 'classnames';
import { Pagination, ThemeProvider, createTheme } from '@mui/material';

interface IPaginationComponentPropsTypes {
    count: number;
    limit: number;
    onChange: (limit: number, offset: number) => void;
    page?: number;
    setPage?: (page: number) => void;
    className?: string;
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#1718FF',
            contrastText: '#FFF',
        },
    },
});

const PaginationComponent = (props: IPaginationComponentPropsTypes) => {
    const { count, limit, onChange, page, setPage, className } = props;

    const handleChange = (e: any, page: number) => {
        const offset = (page - 1) * limit;
        onChange(limit, offset);
        if (setPage) setPage(page);
    };

    return (
        <div className={classNames(styles.paginationContainer, className)}>
            <ThemeProvider theme={theme}>
                <Pagination
                    count={Math.ceil(count / limit)}
                    color='primary'
                    page={page ? page : undefined}
                    onChange={handleChange}
                />
            </ThemeProvider>
        </div>
    );
};

export default PaginationComponent;
