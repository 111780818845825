import { useNavigate } from 'react-router-dom';
import styles from './style.module.scss'
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { CustomInput } from 'src/components/ui/customInput';
import { MyButton } from 'src/components/ui/myButton';
import AuthAPI from 'src/api/AuthAPI';
import notificationActions from 'src/store/actions/notificationActions';
import storageKeys from 'src/utils/storageKeys';
import AirLogo from '../../assets/icons/airSalesWhite.svg';
import Backgroung from '../../assets/img/background.png';
import { AccountIcon, LockIcon } from 'src/assets/icons/icons';

const Auth = () => {
    const [email, setEmail] = useState<string>('');
    const [isErrorUsername, setIsErrorUsername] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('');
    const [isErrorPassword, setIsErrorPassword] = useState<boolean>(false);

    const [isVisionResendEmail, setIsVisionResendEmail] = useState<boolean>(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const checkEmailReady = () => {
        const res = !!email;
        setIsErrorUsername(!res);
        return res;
    };

    const checkPasswordReady = () => {
        // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^\w\s]).{8,}/;
        // const res = passwordRegex.test(password);
        const res = password.length > 7;
        setIsErrorPassword(!res);
        return res;
    };

    const checkFieldsReady = () => {
        const resUsername = checkEmailReady();
        const resPass = checkPasswordReady();
        if (resUsername && resPass) return true;
        else return false;
    };

    const sendReq = () => {
        AuthAPI.auth(email, password)
            .then(response => {
                if (response.status <= 204) {
                    localStorage.setItem(storageKeys.STORAGE_TOKEN_KEY_NAME, response.data.access_token)
                    localStorage.setItem(storageKeys.STORAGE_REFRESH_TOKEN_KEY_NAME, response.data.refresh_token)
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    navigate('/brands')
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
            })
            .catch(err => {
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
    };

    const handleChangeEmail = (value: string) => {
        const reg = /\s/;

        if (reg.test(value)) {
            setEmail(email);
        } else {
            setEmail(value);
        }
    };

    const handleChangePassword = (value: string) => {
        const reg = /\s/;

        if (reg.test(value)) {
            setPassword(password);
        } else {
            setPassword(value);
        }
    };

    const handleSubmit = () => {
        if (checkFieldsReady()) {
            sendReq()
        }
    };

    return (
        <div className={styles.body}>
            <div className={styles.background}>
                <img src={Backgroung} className={styles.backImg} alt="" />
                <div className={styles.content}>
                    <img src={AirLogo} alt=''/>
                    <p>Ассистент менеджера <br/> по продажам</p>
                </div>
            </div>
            <div className={styles.wrapperAuth}>
                <div key='auth' className={styles.formAuth}>
                    <CustomInput
                        placeholder='Введите логин'
                        value={email}
                        onChange={(e) => handleChangeEmail(e.target.value)}
                        name='email'
                        icon={<AccountIcon/>}
                        error={isErrorUsername}
                        errorMessage='Некорректно заполнено поле'
                    />

                    <CustomInput
                        placeholder='Введите пароль'
                        type='password'
                        value={password}
                        onChange={(e) => handleChangePassword(e.target.value)}
                        name='password'
                        icon={<LockIcon/>}
                        error={isErrorPassword}
                        errorMessage='Некорректно заполнено поле'
                    />

                    <MyButton
                        children='Войти в личный кабинет'
                        handleClickProps={handleSubmit}
                        className={styles.authBtn}
                    />

                    <div className={styles.underText}>
                        <div>
                            Если нет аккаунта, 
                            {' '}
                            <span
                                className={styles.rightText}
                                onClick={() => (window.location.pathname = '/registration')}
                            >
                                зарегистрироваться
                            </span>
                        </div>
                    </div>
                </div>
                <span className={styles.bottomText}>Все права защищены 2025</span>
            </div>
        </div>
    )
}

export default Auth
