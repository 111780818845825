import { CustomInput } from 'src/components/ui/customInput';
import styles from './style.module.scss';
import { MyButton } from 'src/components/ui/myButton';
import { useEffect, useState } from 'react';
import IDialog from 'src/models/IDialog';
import PaginationComponent from 'src/components/ui/PaginationComponent';
import DialoguesAPI from 'src/api/DialoguesAPI';
import { useAppDispatch, useAppSelector } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import ChannelsAPI from 'src/api/ChannelsAPI';
import { CustomSelect } from 'src/components/ui/customSelect';
import FilePicker from 'src/components/ui/filePicker';
import { CustomSearch } from 'src/components/ui/customSearch';
import TableMain from 'src/components/table';
import IWazzapChannelRespponse from 'src/models/response/IWazzapChannelResponse';
import ModalContent from 'src/components/modalContent';
import IScenario from 'src/models/IScenario';
import ScenarioAPI from 'src/api/ScenarioAPI';
import { CustomToggle } from 'src/components/ui/customToggle';
import BrandsAPI from 'src/api/BrandsAPI';
import mainActions from 'src/store/actions/mainActions';
import IChannel from 'src/models/IChannel';

const chatTypes = [
    {id: 'whatsapp', name: 'whatsapp'},
    {id: 'whatsgroup', name: 'whatsgroup'},
    {id: 'viber', name: 'viber'},
    {id: 'instagram', name: 'instagram'},
    {id: 'telegram', name: 'telegram'},
    {id: 'vk', name: 'vk'},
    {id: 'avito', name: 'avito'},
    {id: 'telegroup', name: 'telegroup'},
]

const status: any = {
    active: 'канал активен',
    init: 'канал запускается',
    disabled: 'канал выключен: его убрали из подписки или удалили с сохранением сообщений',
    phoneUnavailable: 'нет связи с телефоном',
    qridle: 'необходимо отсканировать QR-код',
    openelsewhere: 'канал авторизован в другом аккаунте Wazzup',
    notEnoughMoney: 'канал не оплачен',
    foreignphone: 'QR канала отсканирован не тем аккаунтом в мессенджере (с другим номером телефона)',
    unauthorized: 'не авторизован',
    waitForPassword: 'нужно ввести пароль для двухфакторной аутентификации',
    blocked: 'Facebook заблокировал канал',
    onModeration: 'канал WABA находится на модерации',
    rejected: 'канал WABA отклонен'
}

const ChannelPage = () => {
    const [dialogues, setDialogues] = useState<IDialog[]>([]);
    
    const [name, setName] = useState<string>('');
    const [chatType, setChatType] = useState<string>('');
    const [amoIsEnabled, setAmoIsAnabled] = useState<boolean>(false);
    const [bitrixIsEnabled, setBitrixIsAnabled] = useState<boolean>(false);
    const [answer_delay, setAnswer_delay] = useState<string>('');
    const [wazzup_id, setWazzup_id] = useState<string>('');
    const [wazzapChannels, setWazzapChannels] = useState<IWazzapChannelRespponse[]>([]);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [scenarios, setScenarios] = useState<IScenario[]>([]);
    const [scenario_id, setScenario_id] = useState<string>('');
    const [isDisabled, setIsDisabled] = useState<boolean>(false);
    const [openai_assistant_id, setOpenai_assistant_id] = useState<string>('');

    const [currentChannel, setCurrentChannel] = useState<IChannel | null>(null);

    const currentBrandFromState = useAppSelector((state) => state.mainState.currentBrand);

    const [searchValue, setSearchValue] = useState<string>('');

    const { brandId, channelId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const handleClick = (channel: IWazzapChannelRespponse) => {
        setName(channel.name);
        setWazzup_id(channel.channelId);
        setChatType(channel.transport);
        setOpenModal(false);
    }

    const getChannel = () => {
        if (brandId && channelId && brandId !== 'all' && channelId !== 'all') {
            ChannelsAPI.getChannel(brandId, channelId)
                .then(response => {
                    if (response.status <= 204) {
                        setName(response.data.name || '');
                        setChatType(response.data.chat_type || '');
                        setAmoIsAnabled(response.data.amo_is_enabled);
                        setBitrixIsAnabled(response.data.bitrix_is_enabled)
                        setAnswer_delay(String(response.data.answer_delay));
                        setScenario_id(response.data.scenario_id);
                        setOpenai_assistant_id(response.data.openai_assistant_id);
                        setCurrentChannel(response.data);
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const getScenarios = () => {
        if (brandId) {
            ScenarioAPI.getScenarios(brandId, 1000, 0)
                .then(response => {
                    if (response.status <= 204) {
                        setScenarios(response.data.scenarios);
                    }
                })
        }
    }

    const getChannels = (channelsWazzap: IWazzapChannelRespponse[]) => {
        // if (brandId) {
        //     ChannelsAPI.getChannels(brandId, 100, 0)
        //         .then(response => {
        //             if (response.status <= 204) {
        //                 const data = channelsWazzap.map(item => ({
        //                     ...item,
        //                     isUsed: !!response.data.channels.find(ch => ch.id === item.channelId)
        //                 }))

        //                 setWazzapChannels(data);
        //             }
        //         })
        // }
    }

    const getWazzapChannels = (token: string) => {
        ChannelsAPI.getWazzupChannels(token)
            .then(response => {
                if (response.status <= 204) {
                    setWazzapChannels(response.data);
                    // getChannels(response.data)
                }
            })
            .catch(() => {
            })
    }

    const handleEditChannel = () => {
        if (brandId && channelId) {
            setIsDisabled(true);
            ChannelsAPI.editChannel(brandId, channelId, {
                amo_is_enabled: amoIsEnabled,
                bitrix_is_enabled: bitrixIsEnabled,
                name,
                answer_delay: Number(answer_delay),
                scenario_id: scenario_id,
                openai_assistant_id: openai_assistant_id,
            })
            .then(response => {
                if (response.status <= 204) {
                    // getChannel();
                    // setIsEditing(false);
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
                setIsDisabled(false);
            })
            .catch((err) => {
                setIsDisabled(false);
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
        }
    }

    const handleSaveChannel = () => {
        if (brandId) {
            setIsDisabled(true);
            ChannelsAPI.createChannel(brandId, {
                name: name,
                wazzup_id,
                answer_delay: Number(answer_delay),
                amo_is_enabled: amoIsEnabled, 
                bitrix_is_enabled: bitrixIsEnabled, 
                chat_type: chatType,
                openai_assistant_id: openai_assistant_id,
            })
            .then(response => {
                if (response.status <= 204) {
                    navigate(`/brand/${brandId}/channels`)
                    dispatch(notificationActions.setMessage({message: 'Успешно'}));
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
                setIsDisabled(false);
            })
            .catch((err) => {
                setIsDisabled(false);
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
        }
    }

    const getBrand = (brandId: string) => {
        BrandsAPI.getBrand(brandId)
            .then(response => {
                if (response.status <= 204) {
                    getWazzapChannels(response.data.brand.wazzup_api_key);
                } else {
                    dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                }
            })
            .catch((err) => {
                dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
            })
    }

    const handleSave = () => {
        if (channelId) {
            handleEditChannel();
        } else {
            handleSaveChannel();
        }
    }

    const handleClickSub = () => {
        navigate(`/brand/${brandId}/channel/${channelId}/subscriptions`);
    }

    const handleClickDialogues = () => {
        if (currentChannel) dispatch(mainActions.setCurrentChannel(currentChannel));
        navigate(`/brand/${brandId}/channel/${channelId}/dialogues`);
    }

    useEffect(() => {
        getChannel();
        if (channelId && channelId !== 'all') {
            getScenarios();
        }
        if (brandId && brandId !== 'all' && !channelId) {
            getBrand(brandId);
        }
    }, [brandId, channelId]);

    return (
        <div className={styles.body}>
            <div className={styles.inputBlock}>
                {!channelId &&
                    <MyButton
                    className={styles.btn}
                        handleClickProps={() => setOpenModal(true)}
                    >
                        Выбрать канал
                    </MyButton>
                }
                {channelId &&
                    <div className={styles.buttonContainer}>
                        <MyButton 
                            handleClickProps={() => handleClickSub()} 
                            disabled={brandId === 'all' || channelId === 'all'}
                        >Перейти к заявкам на уведомление</MyButton>
                        <MyButton 
                            handleClickProps={() => handleClickDialogues()} 
                            disabled={brandId === 'all' || channelId === 'all'}
                        >Перейти к диалогам</MyButton>
                    </div>
                }
                <CustomInput
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder='Название'
                    // disabled={!!channelId}
                />
                {!channelId &&
                    <CustomInput
                        value={wazzup_id}
                        onChange={(e) => setWazzup_id(e.target.value)}
                        placeholder='Wazzup ID'
                    />
                }
                <CustomSelect 
                    options={chatTypes}
                    onChangeMethod={setChatType}
                    selectedId={chatType}
                    placeholder='Тип чата'
                    disabled={!!channelId}
                />
                <div className={styles.toogleBlock}>
                    <CustomToggle
                        handleClick={setAmoIsAnabled}
                        check={amoIsEnabled}
                        id='amoEnabled'
                        nameLabel={amoIsEnabled ? 'Активен' : 'Выключен'}
                        label='Amo'
                    />
                    <CustomToggle
                        handleClick={setBitrixIsAnabled}
                        check={bitrixIsEnabled}
                        id='bitrixIsEnabled'
                        nameLabel={bitrixIsEnabled ? 'Активен' : 'Выключен'}
                        label='Bitrix'
                    />
                </div>
                <CustomInput
                    value={openai_assistant_id}
                    onChange={(e) => setOpenai_assistant_id(e.target.value)}
                    placeholder='Openai Assistant Id'
                    // disabled={!!channelId}
                />
                <CustomInput
                    value={answer_delay}
                    onChange={(e) => setAnswer_delay(e.target.value)}
                    placeholder='Задержка'
                    type='number'
                    // disabled={!!channelId}
                />
                {channelId &&
                    <CustomSelect 
                        options={scenarios}
                        onChangeMethod={setScenario_id}
                        selectedId={scenario_id}
                        placeholder='Сценарий'
                        // disabled={!!channelId}
                    />
                }
                <div className={styles.btnContainer}>
                    <MyButton 
                        disabled={!name || isDisabled} 
                        handleClickProps={handleSave}
                    >
                        Сохранить изменения
                    </MyButton>
                </div>
            </div>
            {openModal && 
                <ModalContent
                    handleClose={() => setOpenModal(false)}
                >
                    <div className={styles.wazzapChannels}>
                        <TableMain>
                            <thead>
                                <tr>
                                    <th>№</th>
                                    <th>Название</th>
                                    <th>Wazzap ID</th>
                                    <th>Статус</th>
                                    {/* <th>Уже используется</th> */}
                                </tr>
                            </thead>
                            <tbody className={styles['']}>
                                {wazzapChannels.map((item, index) => (
                                    <tr className={styles.tableTr} key={item.channelId} onClick={() => handleClick(item)}>
                                        <td>
                                            <div className={styles.tdTextName}>{index+1}</div>
                                        </td>
                                        <td>
                                            <div className={styles.tdTextName}>{item.name}</div>
                                        </td>
                                        <td>
                                            <div className={styles.tdText}>
                                                {item.channelId}
                                            </div>
                                        </td>
                                        <td>
                                            <div className={styles.tdText}>
                                                {status[item.state] || '-'}
                                            </div>
                                        </td>
                                        {/* <td>
                                            <div className={styles.tdText}>
                                                {item.isUsed ? 'Да' : 'Нет'}
                                            </div>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </TableMain>
                    </div>
                </ModalContent>
            }
        </div>
    )
}

export default ChannelPage;
