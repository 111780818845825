import classNames from 'classnames';
import style from './style.module.scss';

interface IProps {
    active: number;
    onChange: (val: number) => void;
    steps: string[];
}

const Steps = ({ active, onChange, steps }: IProps) => {

    const handleClick = (index: number) => {
        onChange(index);
    }

    return (
        <div className={style.stepsContainer}>
            {
                steps.map((step, index) => (
                    <div 
                        className={classNames(style.step, {[style.active]: active === index})} 
                        onClick={() => handleClick(index)}
                    >
                        {step}
                    </div>
                ))
            }
        </div>
    )
}

export default Steps
