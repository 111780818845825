const prepareURL = () => {
    
    const isLocal = window.location.hostname === 'localhost';

    // return 'https://ai.chat-dev.test-loyalty.ru/api';

    const API_HOST = isLocal ? 'ai.chat-dev.test-loyalty.ru' :  window.extended?.REACT_MAIN_BACKEND


    return `https://${API_HOST}/api`;
};

export default prepareURL();
