import { useEffect, useState } from 'react';
import { MyButton } from '../ui/myButton';
import styles from './style.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store';
import mainActions from 'src/store/actions/mainActions';
import Logo from '../../assets/icons/airSales.svg'
import { ArrowSelect, BaseIcon, ChannelsIcon, Companies, DialoguesIcon, PointIcon, ScenariosIcon } from 'src/assets/icons/icons';
import { IChannelFolder, ILink } from 'src/store/slices/mainSlice';
import classNames from 'classnames';

const MainMenu = () => {
    let location = useLocation();

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const [activeItem, setActiveItem] = useState<string>('');

    const menu = useAppSelector((state) => state.mainState.folders);
    const currentBrandFromState = useAppSelector((state) => state.mainState.currentBrand);
    const currentChannelFromState = useAppSelector((state) => state.mainState.currentChannel);

    const { brandId, channelId } = useParams();

    const [openedFolders, setOenedFolders] = useState<any>({});

    const handleClickFolder = (id: string) => {
        if (openedFolders[id]) {
            setOenedFolders({...openedFolders, [id]: false});
        } else {
            setOenedFolders({...openedFolders, [id]: true});
        }
    }

    const handleClickSubFolder = (id: string, brandId: string) => {
        if (id.includes('ch')) {
            if (Object.keys(menu[brandId].folders.channels.folders).length === 0) {
                dispatch(mainActions.getChannels(brandId));
            }
        }
        if (openedFolders[id]) {
            setOenedFolders({...openedFolders, [id]: false});
        } else {
            setOenedFolders({...openedFolders, [id]: true});
        }
    }

    const handleOpenFolder = (id: string, name: string, link: string, fullName: string) => {
        navigate(link);

        dispatch(mainActions.addTab({
            id,
            name,
            link,
            fullName,
        }))
    }

    const handleClick = (link: string) => {
        setActiveItem(link);
        if (link === 'brands') {
            dispatch(mainActions.setCurrentBrand(null));
            navigate('/brands')
        } else {
            dispatch(mainActions.setCurrentChannel(null));
            navigate(`/brand/${currentBrandFromState?.id || 'all'}/${link}`);
        }
    }

    const handleClickDialogues = () => {
        setActiveItem('dialogues');
        navigate(`/brand/${currentBrandFromState?.id || 'all'}/channel/${currentChannelFromState?.id || 'all'}/dialogues`);
    }

    const handleClickScenarios = () => {
        setActiveItem('scenarios');
        navigate(`/brand/${currentBrandFromState?.id || 'all'}/scenarios`);
    }

    const handleClickScenario = () => {
        setActiveItem('scenario');
        navigate(`/brand/${currentBrandFromState?.id || 'all'}/scenario`);
    }

    const findCurrentActiveItem = () => {
        const url = location.pathname;

        if (url.includes('scenario') && !url.includes('scenarios')) {
            setActiveItem('scenario');
        } else if (url.includes('scenarios')) {
            setActiveItem('scenarios');
        } else if (url.includes('knowledge')) {
            setActiveItem('knowledges');
        } else if (!!channelId && (url.includes('dialogues') || url.includes('dialogue'))) {
            setActiveItem('dialogues');
        } else if (!!brandId && (url.includes('channels') || url.includes('channel'))) {
            setActiveItem('channels');
        } else {
            setActiveItem('brands');
        }
    }

    useEffect(() => {
        findCurrentActiveItem();
    }, [location.pathname])

    useEffect(() => {
        findCurrentActiveItem();
    }, [])

    return (
        <div className={styles.container}>
            <div className={styles.logoContainer}>
                <img src={Logo} alt="" />
            </div>
            <div 
                className={classNames(styles.company, {[styles.activeFill]: activeItem === 'brands'})} 
                onClick={() => handleClick('brands')}
            >
                <Companies/>
                Компании
            </div>
            <div 
                className={classNames(styles.menuItem, {[styles.activeStroke]: activeItem === 'channels'})} 
                onClick={() => handleClick('channels')}
            >
                <ChannelsIcon/>
                Каналы
            </div>
            <div 
                className={classNames(styles.menuItem, {[styles.activeFill]: activeItem.includes('scenario')})} 
                onClick={() => handleClickScenarios()}
            >
                <ScenariosIcon/>
                Сценарии
            </div>
            {/* {activeItem.includes('scenario') && */}
                <div className={styles.subMenuItems}>
                    <div 
                        className={classNames(styles.subMenuItem, {[styles.activeSub]: activeItem.includes('scenario') && activeItem !== 'scenario'})}
                        onClick={handleClickScenarios}
                    >
                        Все Сценарии
                    </div>
                    <div 
                        className={classNames(styles.subMenuItem, {[styles.activeSub]: activeItem === 'scenario'})}
                        onClick={handleClickScenario}
                    >
                        Полный скрипт
                    </div>
                </div>
            {/* // } */}
            <div 
                className={classNames(styles.menuItem, {[styles.activeStroke]: activeItem === 'knowledges'})} 
                onClick={() => handleClick('knowledges')}
            >
                <BaseIcon/>
                База знаний
            </div>
            <div 
                className={classNames(styles.menuItem, {[styles.activeStroke]: activeItem === 'dialogues'})} 
                onClick={handleClickDialogues}
            >
                <DialoguesIcon/>
                Диалоги
            </div>
        </div>
    )
}

export default MainMenu;
