import { useEffect, useState } from 'react';
import style from './style.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import KnowledgeAPI from 'src/api/KnowledgeAPI';
import IKnowledge from 'src/models/IKnowledge';
import { ArrowSelect, FolderIcon } from 'src/assets/icons/icons';
import classNames from 'classnames';
import { MyButton } from 'src/components/ui/myButton';
import { CustomInput } from 'src/components/ui/customInput';
import notificationActions from 'src/store/actions/notificationActions';
import { useAppDispatch } from 'src/store';

const Knowledges = () => {

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [directories, setDirectories] = useState<IKnowledge[]>([]);
    const [name, setName] = useState<string>('');

    const [openedFollders, setOpenedFolders] = useState<any>({});
    const [foldersFile, setFoldersFile] = useState<any>({});
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const { brandId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const handleClick = () => {
        setIsOpenModal(true);
    }

    const clickFolder = (folderId: string) => {
        if (openedFollders[folderId]) {
            setOpenedFolders({...openedFollders, [folderId]: false});
        } else {
            setOpenedFolders({...openedFollders, [folderId]: true});
        }

        if (!foldersFile[folderId] && brandId) {
            KnowledgeAPI.getFiles(brandId, folderId)
                .then(response => {
                    if (response.status <= 204) {
                        setFoldersFile({...foldersFile, [folderId]: [...response.data]});
                    }
                })
        }
    }

    const addFile = (e: any, id: string) => {
        e.stopPropagation();
        navigate(`/brand/${brandId}/knowledge/${id}`)
    }

    const getDirectories = () => {
        if (brandId) {
            KnowledgeAPI.getKnowledges(brandId)
                .then(response => {
                    if (response.status <= 204) {
                        setDirectories(response.data);
                    }
                })
        }
    }

    const saveDirectories = () => {
        if (brandId) {
            setIsDisabled(true);
            KnowledgeAPI.createKnowledge(brandId, name)
                .then(response => {
                    if (response.status <= 204) {
                        setName('');
                        setIsOpenModal(false);
                        getDirectories();
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                    setIsDisabled(false);
                })
                .catch((err) => {
                    setIsDisabled(false);
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    useEffect(() => {
        if (brandId) {
            getDirectories();
        } else {
            setDirectories([]);
        }
    }, [brandId])

    return (
        <div>
            <div className={style.buttonContainer}>
                <MyButton 
                    plus 
                    handleClickProps={() => handleClick()} 
                    disabled={brandId === 'all'}
                    hoverText='Добавить папку'
                ></MyButton>
            </div>
            <div className={style.folders}>
                {
                    directories.map(item => (
                        <div key={item.id} className={style.folderContainer}>
                            <div className={style.folder} onClick={() => clickFolder(item.id)}>
                                <div className={classNames(style.icon, {[style.rotate]: !openedFollders[item.id]})}><ArrowSelect/></div>
                                <FolderIcon/>
                                {item.name}
                                <div className={style.addButton} onClick={(e) => addFile(e, item.id)}>
                                    Добавть файл
                                </div>
                            </div>
                            {openedFollders[item.id] &&
                                <div className={style.files}>
                                    {
                                        foldersFile[item.id]?.map((file: any) => (
                                            <div key={file.id}>
                                                {file.name}
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    ))
                }
            </div>
            {isOpenModal &&
                <div className={style.layout}>
                    <div className={style.modalSave}>
                        <span>Название папки</span>
                        <CustomInput
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <div className={style.btnContainer}>
                            <MyButton
                                handleClickProps={() => setIsOpenModal(false)}
                            >
                                Отмена
                            </MyButton>
                            <MyButton
                                handleClickProps={saveDirectories}
                                disabled={!name || isDisabled}
                            >
                                Сохранить изменения
                            </MyButton>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Knowledges

