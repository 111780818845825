import styles from './style.module.scss';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/store';
import notificationActions from 'src/store/actions/notificationActions';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomInput } from 'src/components/ui/customInput';
import { MyButton } from 'src/components/ui/myButton';
import DialoguesAPI from 'src/api/DialoguesAPI';
import IDialog from 'src/models/IDialog';
import moment from 'moment';
import FilePicker from 'src/components/ui/filePicker';
import Modal from 'src/components/modal';

const DialoguePage = () => {

    const [dialog, setDialog] = useState<IDialog | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);

    const { brandId, channelId, dialogueId } = useParams();

    const dispatch = useAppDispatch();

    const navigate = useNavigate();

    const deleteDialog = () => {
        if (brandId && channelId && dialogueId) {
            DialoguesAPI.deleteDialog(brandId, channelId, dialogueId)
                .then(response => {
                    if (response.status <= 204) {
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                        setIsOpenDelete(false);
                        navigate(`/brand/${brandId}/channel/${channelId}/dialogues`);
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    const getDialogue = () => {
        if (brandId && channelId && dialogueId) {
            DialoguesAPI.getDialogue(brandId, channelId, dialogueId)
                .then(response => {
                    if (response.status <= 204) {
                        setDialog(response.data)
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }
    
    const handleTogle = () => {
        if (brandId && channelId && dialogueId) {
            DialoguesAPI.editDialog(brandId, channelId, dialogueId, !dialog?.is_active)
                .then(response => {
                    if (response.status <= 204) {
                        dispatch(notificationActions.setMessage({message: 'Успешно'}));
                    } else {
                        dispatch(notificationActions.setMessage({message: typeof(response.data.detail) === 'string' ? response.data.detail :'Ошибка'}));
                    }
                })
                .catch((err) => {
                    dispatch(notificationActions.setMessage({message: typeof(err.response.data.detail) === 'string' ? err.response.data.detail :'Ошибка'}));
                })
        }
    }

    useEffect(() => {
        getDialogue();
    }, [])

    return (
        <div className={styles.body}>
            {dialogueId &&
                <>
                    <div className={styles.inputBlock}>
                        <CustomInput
                            value={dialog?.thread_id}
                            placeholder='ID треда'
                        />
                        <CustomInput
                            value={moment(dialog?.last_contact).format('DD-MM-YYYY hh:mm:ss')}
                            placeholder='Дата и время последнего сообщения'
                        />
                        <CustomInput
                            value={dialog?.contact_name || ' '}
                            placeholder='Имя клиента'
                        />
                        <CustomInput
                            value={dialog?.contact_username || ' '}
                            placeholder='Никнейм клиента'
                        />
                        <CustomInput
                            value={dialog?.contact_phone || ' '}
                            placeholder='Телефон клиента'
                        />
                        <CustomInput
                            value={dialog?.is_active ? 'Вкл' : 'Выкл'}
                            placeholder='Ассистент'
                        />
                        {
                            <MyButton  
                                handleClickProps={handleTogle}
                            >
                                {dialog?.is_active ? 'Выключить' : 'Включить'}
                            </MyButton> 
                        }
                    </div>
                    <div className={styles.tableBlock}>
                        <div>
                            <table>
                                <thead>
                                    <tr>
                                        <th className={styles.first}>Вопрос</th>
                                        <th>Ответ</th>
                                    </tr>
                                </thead>
                                <tbody className={styles['']}>
                                    {dialog?.dialogue_data.map((item) => (
                                        <tr key={item.id}>
                                            <td className={styles.first}>
                                                <div className={styles.tdTextName}>{item.question}</div>
                                            </td>
                                            <td>
                                                <div className={styles.tdTextName}>{item.data}</div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className={styles.buttonContainer}>
                        <MyButton 
                            handleClickProps={() => setIsOpenDelete(true)}
                        >
                            Удалить диалог
                        </MyButton>
                    </div>
                    {isOpenDelete &&
                        <Modal
                            handleClose={() => setIsOpenDelete(false)}
                            handleOk={deleteDialog}
                            text='Удалить Диалог?'
                        />
                    }
                </>
            }
        </div>
    )
}

export default DialoguePage;
